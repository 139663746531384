<template>
  <div>
    <b-card
      class="mt-1 border border-dark shadow-none dashboard-card"
      no-body
    >
      <!-- #region::Title -->
      <b-row class="spaced-title">
        <b-col>
          <h4 class="font-weight-bolder">
            Pagos registrados
          </h4>
        </b-col>
      </b-row>
      <!-- #endregion::Title -->

      <hr class="my-0">

      <!-- #region::Subtitle -->
      <b-row class="spaced-title">
        <b-col class="d-flex">
          <h5 class="font-weight-bolder">
            {{ dashboardRegisteredPaymentsTotal | currency }}
          </h5>
          <h4 class="ml-50 pill-alert alert-gray">
            {{ dashboardDateRange.from | moment("MMMM Do YYYY") }} - {{ dashboardDateRange.to | moment("MMMM Do YYYY") }}
          </h4>
        </b-col>
      </b-row>
      <!-- #endregion::Subtitle -->

      <!-- #region::Registered payments list -->
      <b-card-text>
        <vue-good-table
          v-if="!showDashboardCharts"
          id="reports-table"
          class="vgt-table-checkbox-styled"
          style-class="vgt-table condensed align-text-bottom"
          :columns="columns"
          :rows="dashboardRegisteredPaymentsList"
          :sort-options="{
            enabled: false,
          }"
          compact-mode
          @on-cell-click="onCellClick"
        >

          <!-- #region::Empty response -->
          <div slot="emptystate">
            <b-alert
              variant="warning"
              class="my-0"
              show
            >
              <div class="alert-body">
                <span>No se encontraron pagos.</span>
              </div>
            </b-alert>
          </div>
          <!-- #endregion::Empty response -->

          <!-- #region::Column headers -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              <h6 class="capitalize-col-hd spaced-cell">
                <strong>{{ props.column.label }}</strong>
              </h6>
            </span>
          </template>
          <!-- #endregion::Column headers -->

          <!-- #region::Modifying rows cells -->
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- #region::Location cell -->
            <div
              v-if="props.column.field === 'location'"
              class="spaced-cell"
            >
              <h6 class="text-nowrap text-primary">
                {{ props.formattedRow[props.column.field] }}
              </h6>
            </div>
            <!-- #endregion::Location cell -->

            <!-- #region::Custom general cell -->
            <div
              v-else
              class="spaced-cell"
            >
              <h6 class="text-nowrap">
                {{ props.formattedRow[props.column.field] }}
              </h6>
            </div>
            <!-- #endregion::Custom general cell -->

          </template>
          <!-- #endregion::Modifying rows cells -->

        </vue-good-table>

        <BarChart
          v-else
          class="px-1 pb-2"
          type="horizontalBar"
          :chart-data="{labels: labelsLocations,
                        datasets: datasets,}"
          :chart-options="chartOptions"
        />

      </b-card-text>
      <!-- #endregion::Registered payments list -->

    </b-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BCardText, BAlert, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import validateCurrency from '@/helpers/CurrencyFormat'
import BarChart from '@/components/charts/BarChart.vue'

const LOCATION_COLUMN_HEADER = 'Ubicación'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BarChart,
    BCardText,
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: 'Ubicación',
          field: 'location',
          tdClass: 'pointer-cursor-cell',
        },
        {
          label: 'Total de pagos',
          field: 'total',
          formatFn: this.isValidCurrency,
        },
      ],

      /**
       * Configuración de la gráfica
       */
      chartOptions: {
        responsive: true, // Ajusta el tamaño de la gráfica
        maintainAspectRatio: false, // Permite mantener el aspecto de la gráfica
        /**
         * Configuración de la leyenda (oculta)
         */
        legend: {
          display: false,
        },
        /**
         * Configuración de los ejes
         */
        scales: {
          // Configura el eje X
          xAxes: [{
            ticks: {
              beginAtZero: true,
              callback: value => validateCurrency(value), // Agrega el formato de moneda
            },
          }],
          // Configura el eje Y
          yAxes: [{
            barPercentage: 0.5, // Ajusta el ancho de las barras
            categoryPercentage: 1, // Ajusta el espacio entre las barras
          }],
        },
        /**
         * Configuración de los tooltips
         */
        tooltips: {
          callbacks: {
            // Configura el formato de moneda en el tooltip
            label: tooltipItem => {
              const value = validateCurrency(tooltipItem.xLabel)
              return value
            },
          },
        },
        /**
         * Evento que se ejecuta al dar click en la gráfica
         */
        onClick: (_, array) => {
          if (array[0]) {
            const chartElement = array[0]
            // eslint-disable-next-line no-underscore-dangle
            const { label } = chartElement._model

            if (label !== 'Tienda') {
              this.redirectToTab(`Bodega ${label}`, label)
            } else {
              this.redirectToTab(label, label)
            }
          }
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      getDashboardDateRange: 'payments/getDashboardDateRange',
      getShowDashboardCharts: 'payments/getShowDashboardCharts',
      getDashboardRegisteredPaymentsList: 'payments/getDashboardRegisteredPaymentsList',
      getDashboardRegisteredPaymentsTotal: 'payments/getDashboardRegisteredPaymentsTotal',
    }),
    /**
     * Rango de fecha seleccionado
     */
    dashboardDateRange: {
      get() { return this.getDashboardDateRange },
    },
    /**
     * Indica si se muestran las gráficas
     */
    showDashboardCharts: {
      get() { return this.getShowDashboardCharts },
    },
    /**
     * Lista de pagos registrados
     */
    dashboardRegisteredPaymentsList: {
      get() { return this.getDashboardRegisteredPaymentsList },
    },
    /**
     * Total de pagos registrados
     */
    dashboardRegisteredPaymentsTotal: {
      get() { return this.getDashboardRegisteredPaymentsTotal },
    },
    /**
     * Labels for chart
     */
    labelsLocations() {
      return this.dashboardRegisteredPaymentsList.map(payment => payment.location)
    },
    /**
     * Datasets for chart
     */
    datasets() {
      return [
        {
          data: this.dashboardRegisteredPaymentsList.map(payment => payment.total),
          backgroundColor: ['#FFD400', '#1E4F9A', '#0CA852'],
        },
      ]
    },
  },
  methods: {
    /**
    * Validacion de datos numéricos
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value) {
      return validateCurrency(value)
    },

    /**
     * Evento que se ejecuta al dar click en una celda de la tabla
     * @summary Emite un evento con el tipo de filtro y el valor a filtrar
     * @param {Object} params - Objeto con los parámetros del evento
     */
    onCellClick(params) {
      const { label } = params.column
      if (label === LOCATION_COLUMN_HEADER) {
        const { location } = params.row
        const { filter } = params.row
        this.redirectToTab(filter, location)
      }
    },

    /**
     * Redirige a la pestaña de la ubicación seleccionada
     * @summary Emite un evento para redirigir a la pestaña de la ubicación seleccionada
     * @param {String} filter - Filtro de la ubicación
     * @param {String} location - Ubicación seleccionada
     */
    redirectToTab(filter, location) {
      console.log('redirectToTab', filter, location)
      this.$emit('on:filter-by-location', {
        filter,
        location,
        tab: 'all',
        type: 'registeredPayments',
        dashboardDateRange: this.dashboardDateRange,
      })
    },
  },
}
</script>
