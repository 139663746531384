<template>
  <div>
    <TransactionsListsFilters />

    <b-row>
      <b-col
        v-if="$ability.can('read payments_seller', 'Transactions')"
        md="6"
      >
        <BasicSkeleton
          v-show="isLoadingTransactionsLists"
          height="450px"
        />
        <TransactionsSellersPaymentsList
          v-show="!isLoadingTransactionsLists"
          ref="seller-list"
        />
      </b-col>
      <b-col
        v-if="$ability.can('read payments_wholealers', 'Transactions')"
        md="6"
      >
        <BasicSkeleton
          v-show="isLoadingTransactionsLists"
          height="450px"
        />
        <TransactionsWholesalersPaymentsList
          v-show="!isLoadingTransactionsLists"
          ref="wholesaler-list"
        />
      </b-col>
      <b-col
        v-if="$ability.can('read payments_customers', 'Transactions')"
        md="12"
      >
        <BasicSkeleton
          v-show="isLoadingTransactionsLists"
          height="450px"
        />
        <TransactionsCustomersPaymentsList
          v-show="!isLoadingTransactionsLists"
          ref="customers-list"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCol, BRow,
} from 'bootstrap-vue'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import TransactionsListsFilters from '@/modules/reports/payments/components/transactions/TransactionsListsFilters.vue'
import TransactionsSellersPaymentsList from '@/modules/reports/payments/components/transactions/TransactionsSellersPaymentsList.vue'
import TransactionsCustomersPaymentsList from '@/modules/reports/payments/components/transactions/TransactionsCustomersPaymentsList.vue'
import TransactionsWholesalersPaymentsList from '@/modules/reports/payments/components/transactions/TransactionsWholesalersPaymentsList.vue'

import getError from '@/helpers/ErrorsHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BRow,
    BasicSkeleton,
    TransactionsListsFilters,
    TransactionsSellersPaymentsList,
    TransactionsCustomersPaymentsList,
    TransactionsWholesalersPaymentsList,
  },
  data() {
    return {
      isLoadingTransactionsLists: false,
    }
  },
  computed: {
    ...mapGetters({
      getTransactionsDateRange: 'payments/getTransactionsDateRange',
      /**
       * Filtros globales
       */
      getFilteredTransactionsDateRange: 'payments/getFilteredTransactionsDateRange',
    }),
    /**
     * Rango de fechas para filtrar las transacciones
     */
    trasnactionsDateRange: {
      get() { return this.getTransactionsDateRange },
    },
    /**
     * Rango de fechas para filtrar las transacciones (Filtro global)
     */
    filteredTransactionsDateRange: {
      get() { return this.getFilteredTransactionsDateRange },
      set(value) { this.setFilteredTransactionsDateRange(value) },
    },
  },
  watch: {
    async trasnactionsDateRange() {
      await this.loadTransactionsLists()
    },
  },
  async mounted() {
    this.loadTransactionsLists()
  },
  methods: {
    ...mapActions({
      /**
       * Filtros globales
       */
      setFilteredTransactionsDateRange: 'filters/setFilteredTransactionsDateRange',
    }),
    /**
    * Evento de filtrado
    *
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de pagos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleFilter(filter) {
      this.filteredTransactionsDateRange = filter.value
      this.loadTransactionsLists()
    },

    /**
    * Carga las listas del dashboard
    *
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga los registros de cada una de las tablas del dashboard
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de pagos
    */
    async loadTransactionsLists() {
      try {
        this.isLoadingTransactionsLists = true
        await this.$refs['seller-list']?.loadPaymentsListWithVerification()
        await this.$refs['wholesaler-list']?.loadPaymentsListWithVerification()
        await this.$refs['customers-list']?.loadPaymentsListWithVerification()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isLoadingTransactionsLists = false
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
