<template>
  <div>
    <b-card
      class="border border-dark shadow-none dashboard-card"
      no-body
    >

      <!-- #region::Title -->
      <b-row class="spaced-title">
        <b-col>
          <h4 class="font-weight-bolder">
            Formas de pago
          </h4>
        </b-col>
      </b-row>
      <!-- #endregion::Title -->

      <hr class="my-0">

      <!-- #region::Subtitle -->
      <b-row class="spaced-title">
        <b-col class="d-flex">
          <h5 class="font-weight-bolder">
            {{ dashboardPaymentsMethodsTotal | currency }}
          </h5>
          <h4 class="ml-50 pill-alert alert-gray">
            {{ dashboardDateRange.from | moment("MMMM Do YYYY") }} - {{ dashboardDateRange.to | moment("MMMM Do YYYY") }}
          </h4>
        </b-col>
      </b-row>
      <!-- #endregion::Subtitle -->

      <!-- #region::Payment methods list -->
      <b-card-text>
        <vue-good-table
          id="reports-table"
          class="vgt-table-checkbox-styled"
          style-class="vgt-table condensed align-text-bottom"
          :columns="columns"
          :rows="dashboardPaymentsMethodsList"
          :sort-options="{
            enabled: false,
          }"
          compact-mode
          @on-cell-click="onCellClick"
        >

          <!-- #region::Empty response -->
          <div slot="emptystate">
            <b-alert
              variant="warning"
              class="my-0"
              show
            >
              <div class="alert-body">
                <span>No se encontraron pagos.</span>
              </div>
            </b-alert>
          </div>
          <!-- #endregion::Empty response -->

          <!-- #region::Column headers -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              <h6 class="capitalize-col-hd spaced-cell">
                <strong>{{ props.column.label }}</strong>
              </h6>
            </span>
          </template>
          <!-- #endregion::Column headers -->

          <!-- #region::Modifying rows cells -->
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- #region::Location cell -->
            <div
              v-if="props.column.field === 'paymentMethod'"
              class="compact-cell"
            >
              <h6 class="text-nowrap text-primary">
                {{ props.formattedRow[props.column.field] }}
              </h6>
            </div>
            <!-- #endregion::Location cell -->

            <!-- #region::Custom general cell -->
            <div
              v-else
              class="compact-cell"
            >
              <h6 class="text-nowrap">
                {{ props.formattedRow[props.column.field] }}
              </h6>
            </div>
            <!-- #endregion::Custom general cell -->

          </template>
          <!-- #endregion::Modifying rows cells -->

        </vue-good-table>
      </b-card-text>
      <!-- #endregion::Payment methods list -->

    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BCardText, BAlert, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import validateCurrency from '@/helpers/CurrencyFormat'

const METHOD_NAME_COLUMN_HEADER = 'Forma de pago'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BCardText,
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: 'Forma de pago',
          field: 'paymentMethod',
          tdClass: 'pointer-cursor-cell',
        },
        {
          label: 'Total de pagos',
          field: 'total',
          formatFn: this.isValidCurrency,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getDashboardDateRange: 'payments/getDashboardDateRange',
      getDashboardPaymentsMethodsList: 'payments/getDashboardPaymentsMethodsList',
      getDashboardPaymentsMethodsTotal: 'payments/getDashboardPaymentsMethodsTotal',
    }),
    /**
     * Rango de fecha seleccionado
     */
    dashboardDateRange: {
      get() { return this.getDashboardDateRange },
    },
    /**
     * Lista de métodos de pago
     */
    dashboardPaymentsMethodsList: {
      get() { return this.getDashboardPaymentsMethodsList },
    },
    /**
     * Total de métodos de pago
     */
    dashboardPaymentsMethodsTotal: {
      get() { return this.getDashboardPaymentsMethodsTotal },
    },
  },
  methods: {
    /**
    * Validacion de datos numéricos
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value, field) {
      if (field.paymentMethod === 'Dólares') {
        return `${validateCurrency(field.total)} MXN (${validateCurrency(field.usd)} USD)`
      }

      return validateCurrency(value)
    },
    /**
     * Evento que se ejecuta al dar click en una celda de la tabla
     * @summary Emite un evento con el tipo de filtro y el valor a filtrar
     * @param {Object} params - Objeto con los parámetros del evento
     */
    onCellClick(params) {
      const { label } = params.column
      if (label === METHOD_NAME_COLUMN_HEADER) {
        const { paymentMethod } = params.row
        this.$emit('on:filter-by-method', {
          tab: 'all',
          paymentMethod,
          type: 'paymentsMethods',
          dashboardDateRange: this.dashboardDateRange,
        })
      }
    },
  },
}
</script>
