<template>
  <div>

    <PaymentsListFilters
      v-show="!isLoadingPayments"
      title="refunds"
      :show-delivery-date-filter="false"
      :show-order-payment-status-filter="false"
      :show-cash-delivery-status-filter="false"
      :download-file-base-link="downloadFileBaseLink"
      @on:column-option-change="handleColumnOptionChange"
      @on:filter="handleFilter"
    />

    <template v-if="isLoadingPayments">
      <BasicSkeleton height="150px" />
      <BasicSkeleton height="550px" />
    </template>

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <vue-good-table
            v-if="availablePayments"
            id="reports-table"
            ref="payments-products-table"
            class="vgt-table-checkbox-styled"
            style-class="vgt-table condensed align-text-bottom"
            :columns="columns"
            :rows="payments"
            :sort-options="{
              enabled: false,
            }"
          >

            <!-- #region::Empty response -->
            <div slot="emptystate">
              No hay pagos para mostrar.
            </div>
            <!-- #endregion::Empty response -->

            <!-- #region::Column headers -->
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span>
                <h6 class="capitalize-col-hd">
                  <strong>{{ props.column.label }}</strong>
                </h6>
              </span>
            </template>
            <!-- #endregion::Column headers -->

            <!-- #region::Modifying rows cells -->
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- #region::Refund id cell -->
              <div
                v-if="props.column.field === 'DevolutionID'"
                class="mt-2"
              >
                <router-link
                  :to="{ name: 'refund-details', params: { id: props.row.DevolutionID } }"
                  target="_blank"
                >
                  <h6 class="text-nowrap text-primary">
                    {{ props.row.DevolutionID }}
                  </h6>
                </router-link>
              </div>
              <!-- #endregion::Refund id cell -->

              <!-- #region:Invoice required cell -->
              <div
                v-else-if="props.column.field === 'Invoice'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.Invoice.value }}
                </h6>
              </div>
              <!-- #endregion:Invoice required cell -->

              <!-- #region::Payment confirmation cell -->
              <div
                v-else-if="props.column.field === 'status'"
                class="mt-1"
              >
                <div class="d-flex justify-content-left">
                  <div class="align-self-center"><h6
                    class="text-nowrap pill-alert mr-25"
                    :class="[backgroundPaymentStatusColor(props.row.StatusPayment)]"
                  >
                    {{ props.row.StatusPayment }}
                  </h6></div>

                  <b-button
                    v-if="canShowConfirmPaymentButton(props.row.StatusPayment)"
                    v-b-tooltip.hover.top="'Confirmar'"
                    variant="flat-success"
                    class="btn-icon p-25"
                    @click="onConfirmPayment(props.row)"
                  >
                    <feather-icon icon="CheckIcon" />
                  </b-button>

                  <b-button
                    v-if="canShowCancelPaymentButton(props.row.StatusPayment)"
                    v-b-tooltip.hover.top="'Cancelar'"
                    variant="flat-danger"
                    class="btn-icon p-25"
                    @click="onCancelPayment(props.row)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
              <!-- #endregion::Payment confirmation cell -->

              <!-- #region::Payment options cell -->
              <div
                v-else-if="props.column.field === 'paymentOptions'"
                class="mt-1"
              >
                <div class="d-flex justify-content-left">

                  <!-- #region::Preview image button -->
                  <b-button
                    v-if="$ability.can('read acquittance', 'Payments')"
                    v-b-tooltip.hover.top="'Ver recibo'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    @click="$refs.imagePreviewModal.show(props.row.Image)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <!-- #endregion::Preview image button -->

                  <!-- #region::Download receipt button -->
                  <b-button
                    v-if="$ability.can('download acquittance', 'Payments')"
                    v-b-tooltip.hover.top="'Descargar recibo'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    @click="downloadImage(props.row.IdPaymentKey)"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>
                  <!-- #endregion::Download receipt button -->

                  <!-- #region::Edit payment button -->
                  <b-button
                    v-if="canShowEditPaymentButton(props.row.StatusPayment)"
                    v-b-tooltip.hover.top="'Editar'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    @click="onOpenUpdatePaymentModal(props.row)"
                  >
                    <feather-icon icon="Edit3Icon" />
                  </b-button>
                  <!-- #endregion::Edit payment button -->

                </div>
              </div>
              <!-- #endregion::Payment options cell -->

              <!-- #region::Custom general cell -->
              <div
                v-else
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.formattedRow[props.column.field] }}
                </h6>
              </div>
              <!-- #endregion::Custom general cell -->

            </template>
            <!-- #endregion::Modifying rows cells -->

          </vue-good-table>

          <!-- #region::Alert when no transfers are available -->
          <b-alert
            v-if="!availablePayments && !isLoadingPayments"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron pagos.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no transfers are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availablePayments"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalPayments"
    />
    <!-- #endregion end::Pagination & items per list -->

    <RefundPaymentModal
      v-if="$ability.can('edit refund', 'Payments')"
      ref="refundPaymentModal"
      @update-payment="onUpdatePayment"
    />

    <ImagePreviewModal ref="imagePreviewModal" />

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BCardText, BAlert, VBTooltip, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import ImagePreviewModal from '@/components/modals/ImagePreviewModal.vue'
import PaymentsListFilters from '@/modules/reports/payments/components/PaymentsListFilters.vue'
import RefundPaymentModal from '@/modules/store/refunds/components/modals/RefundPaymentModal.vue'

import getError from '@/helpers/ErrorsHandler'
import validateCurrency from '@/helpers/CurrencyFormat'
import { adminLevel, ADMIN_USER_LEVELS } from '@/helpers/UserLevel'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  components: {
    BCard,
    BAlert,
    BButton,
    BCardText,
    VueGoodTable,
    BasicSkeleton,
    BasicPaginator,
    ImagePreviewModal,
    RefundPaymentModal,
    PaymentsListFilters,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    /**
     * Titulo que se muestra en la pestaña
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Enlace base para descargar el archivo CSV
     */
    downloadFileBaseLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lastPage: 0,
      totalPayments: 0,
      columns: [
        {
          label: 'Ubicación devolución',
          field: 'Location',
        },
        {
          label: 'ID devolución',
          field: 'DevolutionID',
        },
        {
          label: 'ID orden origen',
          field: 'OrderID',
        },
        {
          label: 'Creador',
          field: 'Creator',
          hidden: !this.$ability.can('read creator_order', 'Payments'),
        },
        {
          label: 'Total MXN devolución',
          field: 'TotalMxn',
          formatFn: this.isValidCurrency,
        },
        {
          label: 'Saldo a favor',
          field: 'saldo_favor',
          formatFn: this.isValidCurrency,
        },
        {
          label: 'ID pago',
          field: 'ID',
        },
        {
          label: 'Folio de pago',
          field: 'Invoice',
          tdClass: this.invoiceCellStyle,
        },
        {
          label: 'Cantidad',
          field: 'Payment',
          formatFn: this.isValidCurrency,
        },
        {
          label: 'Moneda',
          field: 'Currency',
        },
        {
          label: 'T.C.',
          field: 'ExchangeRate',
          formatFn: this.isValidCurrency,
        },
        {
          label: 'Total MXN',
          field: 'PaymentWithExchangeRate',
          formatFn: this.isValidCurrency,
        },
        {
          label: 'Fecha de comprobante',
          field: 'VoucherDate',
          formatFn: this.isValidDate,
        },
        {
          label: 'Forma de pago',
          field: 'PaymentMethod',
        },
        {
          label: 'Registrado',
          field: 'RegisteredAt',
          formatFn: this.isValidDate,
        },
        {
          label: 'Estatus de pago',
          field: 'status',
        },
        {
          label: 'Recibo',
          field: 'paymentOptions',
        },
      ],
      payments: [],
      isLoadingPayments: false,

      // Role validation
      adminLevel,
      ADMIN_USER_LEVELS,
    }
  },
  computed: {
    ...mapGetters({
      getListsParams: 'payments/getListsParams',
      getRefundsColumnsList: 'payments/getRefundsColumnsList',
      /**
       * Filtros globales
       */
      getFilteredCreator: 'filters/getFilteredCreator',
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredLocation: 'filters/getFilteredLocation',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredOrderStatus: 'filters/getFilteredOrderStatus',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
      getFilteredPaymentStatus: 'filters/getFilteredPaymentStatus',
      getFilteredPaymentMethod: 'filters/getFilteredPaymentMethod',
      getFilteredPaymentCurrency: 'filters/getFilteredPaymentCurrency',
      getFilteredVoucherDateRange: 'filters/getFilteredVoucherDateRange',
      getFilteredRegisterDateRange: 'filters/getFilteredRegisterDateRange',
    }),
    /**
     * Listas de parametros de filtrado de pestañas
     */
    listsParams: {
      get() { return this.getListsParams },
    },
    /**
     * Lista de columnas
     */
    columnsList: {
      get() { return this.getRefundsColumnsList },
    },
    /**
     * Indica el creador seleccionado (paginado global)
     */
    filteredCreator: {
      get() { return this.getFilteredCreator },
      set(value) { this.setFilteredCreator(value) },
    },
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Indica la ubicación seleccionada (paginado global)
     */
    filteredLocation: {
      get() { return this.getFilteredLocation },
      set(value) { this.setFilteredLocation(value) },
    },
    /**
     * Indica el término de búsqueda (paginado global)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica el estatus de orden seleccionado (paginado global)
     */
    filteredOrderStatus: {
      get() { return this.getFilteredOrderStatus },
      set(value) { this.setFilteredOrderStatus(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    /**
     * Indica el estatus de pago seleccionado (paginado global)
     */
    filteredPaymentStatus: {
      get() { return this.getFilteredPaymentStatus },
      set(value) { this.setFilteredPaymentStatus(value) },
    },
    /**
     * Indica el método de pago seleccionado (paginado global)
     */
    filteredPaymentMethod: {
      get() { return this.getFilteredPaymentMethod },
      set(value) { this.setFilteredPaymentMethod(value) },
    },
    /**
     * Indica la moneda de pago seleccionada (paginado global)
     */
    filteredPaymentCurrency: {
      get() { return this.getFilteredPaymentCurrency },
      set(value) { this.setFilteredPaymentCurrency(value) },
    },
    /**
     * Indica el rango de fechas de comprobante seleccionado (paginado global)
     */
    filteredVoucherDateRange: {
      get() { return this.getFilteredVoucherDateRange },
      set(value) { this.setFilteredVoucherDateRange(value) },
    },
    /**
     * Indica el rango de fechas de registro seleccionado (paginado global)
     */
    filteredRegisterDateRange: {
      get() { return this.getFilteredRegisterDateRange },
      set(value) { this.setFilteredRegisterDateRange(value) },
    },
    availablePayments() {
      return this.totalPayments > 0
    },
    isLoadedParamsForList() {
      return this.listsParams.findIndex(params => params.title === this.title) !== -1
    },
    /**
     * Indica si el usuario puede gestionar pagos
     */
    canManagePayments() {
      return this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
  },

  /**
   * Hook que se ejecuta cuando el componente es montado
   *
   * @summary Si la lista ya ha sido cargada previamente, se cargan las columnas
   * que han sido seleccionadas desactivadas
   */
  mounted() {
    if (this.isLoadedParamsForList) {
      const { selectedColumns } = this.getListsParams.find(p => p.title === this.title)
      const hiddenColumns = selectedColumns.filter(c => c.active === false)

      hiddenColumns.forEach(hiddenColumn => {
        this.handleColumnOptionChange(hiddenColumn)
      })
    }
  },

  /**
  * Hook que se ejecuta cuando el componente es creado
  *
  * @summary Carga la lista de pagos
  */
  async created() {
    await this.loadPaymentsListWithVerification()
  },

  methods: {
    ...mapActions({
      cancelPayment: 'refunds/cancelPayment',
      downloadImage: 'refunds/downloadImage',
      confirmPayment: 'refunds/confirmPayment',
      loadRefundPayments: 'payments/loadRefundPayments',
      setSelectedRefundPayment: 'payments/setSelectedRefundPayment',
      /**
       * Cargar informacion para los filtros
       */
      loadCreatorsSuggests: 'payments/loadCreatorsSuggests',
      /**
       * Filtros globales
       */
      setFilteredCreator: 'filters/setFilteredCreator',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredLocation: 'filters/setFilteredLocation',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
      setFilteredOrderStatus: 'filters/setFilteredOrderStatus',
      setFilteredPaymentStatus: 'filters/setFilteredPaymentStatus',
      setFilteredPaymentMethod: 'filters/setFilteredPaymentMethod',
      setFilteredPaymentCurrency: 'filters/setFilteredPaymentCurrency',
      setFilteredVoucherDateRange: 'filters/setFilteredVoucherDateRange',
      setFilteredRegisterDateRange: 'filters/setFilteredRegisterDateRange',
    }),

    /**
    * Evento de filtrado
    *
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de pagos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'creator': this.filteredCreator = filter.value
          break
        case 'location': this.filteredLocation = filter.value
          break
        case 'orderStatus': this.filteredOrderStatus = filter.value
          break
        case 'voucherDate': this.filteredVoucherDateRange = filter.value
          break
        case 'registerDate': this.filteredRegisterDateRange = filter.value
          break
        case 'paymentStatus': this.filteredPaymentStatus = filter.value
          break
        case 'paymentMethod': this.filteredPaymentMethod = filter.value
          break
        case 'paymentCurrency': this.filteredPaymentCurrency = filter.value
          break
        default: this.filteredSearchTerm = filter.value
          this.filteredCurrentPage = 1
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage()
          break
      }

      this.loadPaymentsListWithVerification()
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    *
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadPaymentsListWithVerification() {
      await this.loadPaymentsList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.filteredCurrentPage = currentPage
      this.filteredPerPage = perPage
      this.loadPaymentsList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.payments.length === 0) {
        this.filteredCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availablePayments) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadPaymentsList()
        }
      }
    },

    /**
    * Carga de registros de tipo pagos
    *
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de pagos y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de pagos
    */
    async loadPaymentsList() {
      try {
        this.isLoadingPayments = true

        const response = await this.loadRefundPayments(this.loadParams())
        this.payments = response.data.data.reports.data
        this.totalPayments = response.data.data.reports.total
        this.lastPage = response.data.data.reports.last_page

        if (this.$ability.can('read creator_order', 'Payments')) {
          await this.loadCreatorsSuggests()
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isLoadingPayments = false
      }
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista. Determina
    * si se muestra solo pagos en efectivo
    */
    loadParams() {
      return {
        title: this.title,
        perPage: this.filteredPerPage,
        currentPage: this.filteredCurrentPage,
        locationPaymentTerm: this.filteredLocation,
        orderStatusTerm: this.filteredOrderStatus,
        searchTerm: this.filteredSearchTerm,
        creatorTerm: this.filteredCreator,
        statusConfirmationTerm: this.filteredPaymentStatus,
        paymentMethodTerm: this.filteredPaymentMethod,
        paymentCurrencyTerm: this.filteredPaymentCurrency,
        voucherDateStartTerm: this.filteredVoucherDateRange?.from,
        voucherDateEndTerm: this.filteredVoucherDateRange?.to,
        registerDateStartTerm: this.filteredRegisterDateRange?.from,
        registerDateEndTerm: this.filteredRegisterDateRange?.to,

        selectedColumns: this.isLoadedParamsForList
          ? this.getListsParams.find(p => p.title === this.title).selectedColumns
          : this.columnsList.map(a => ({ ...a })),
      }
    },

    /**
    * Estilo de color de estatus de pago
    *
    * @summary Determina a partir del estatus de pago el color del elemento
    * @param {String} paymentStatus - Estatus de pago
    * @return {String} Clase de color
    */
    backgroundPaymentStatusColor(paymentStatus) {
      switch (paymentStatus) {
        case 'Pago pendiente':
          return 'alert-dark-yellow'
        case 'Pendiente':
          return 'alert-dark-blue'
        case 'Cancelado':
          return 'alert-dark-red'
        default:
          return 'alert-dark-green'
      }
    },

    /**
    * Botón de confirmación de pago
    *
    * @summary Determina si se muestra el botón de confirmación de pago
    * @param {String} paymentStatus - Estatus de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowConfirmPaymentButton(paymentStatus) {
      const statuses = ['Pendiente']
      return this.$ability.can('confirm refund', 'Payments')
        && statuses.includes(paymentStatus)
        && this.canManagePayments
    },

    /**
    * Botón de cancelación de pago
    *
    * @summary Determina si se muestra el botón de cancelación de pago
    * @param {String} paymentStatus - Estatus de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowCancelPaymentButton(paymentStatus) {
      const statuses = ['Pendiente', 'Pagado']
      return this.$ability.can('cancel refund', 'Payments')
        && statuses.includes(paymentStatus)
        && this.canManagePayments
    },

    /**
    * Botón de edición de pago
    *
    * @summary Determina si se muestra el botón de edición de pago
    * @param {String} paymentStatus - Estatus de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowEditPaymentButton(paymentStatus) {
      const statuses = ['Pendiente', 'Cancelado']
      return this.$ability.can('edit refund', 'Payments')
        && statuses.includes(paymentStatus)
        && this.canManagePayments
    },

    /**
    * Confirmación de pago
    *
    * @summary Atiende el evento para confirmar un pago, si la respuesta es positiva,
    * se actualiza el pago directamente en la tabla y los relacionados con la misma orden
    * @param {Object} payment - Objeto de pago
    */
    async onConfirmPayment(payment) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        const formData = new FormData()
        formData.append('idPaymentDevolution', payment.IdPaymentKey)
        formData.append('row', 1)

        const response = await this.confirmPayment(formData)

        if (response.status === 200) {
          const { report } = response.data.data
          this.$set(payment, 'StatusPayment', 'Pagado')

          if (report.PaymentMethod === 'Saldo a Favor') {
            this.$set(payment, 'saldo_favor', report.saldo_favor)
          }

          this.showToast(
            'Pago confirmado',
            'El pago seleccionado ha sido confirmado correctamente.',
            'success',
          )
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },

    /**
    * Cancelación de pago
    *
    * @summary Atiende el evento para cancelar un pago, si la respuesta es positiva,
    * se actualiza el pago directamente en la tabla
    * @param {Object} payment - Objeto de pago
    */
    async onCancelPayment(payment) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        const formData = new FormData()
        formData.append('idPaymentDevolution', payment.IdPaymentKey)
        formData.append('row', 1)

        const response = await this.cancelPayment(formData)

        if (response.status === 200) {
          const { report } = response.data.data
          this.$set(payment, 'StatusPayment', 'Cancelado')

          if (report.PaymentMethod === 'Saldo a Favor') {
            this.$set(payment, 'saldo_favor', report.saldo_favor)
          }

          this.showToast(
            'Pago cancelado',
            'El pago seleccionado ha sido cancelado correctamente.',
            'success',
          )
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },

    /**
    * Estilos de celdas
    *
    * @summary Establece los estilos de las celdas de la tabla. Amariilo si el inventario es
    * mayor a 0, rojo si es menor o igual a 0
    * @param {Object} row - Objeto de la fila
    */
    invoiceCellStyle(row) {
      return row?.Invoice?.repeat === 'true' ? 'beige-cell' : ''
    },

    /**
    * Validacion de datos numéricos
    *
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value) {
      return validateCurrency(value)
    },

    /**
     * Valida si la fecha es válida
     *
     * @summary Valida si la fecha es válida y se aplica el formato de fecha
     * @param {String} value - Valor a validar
     * @return {String} Valor con formato de fecha
     */
    isValidDate(value) {
      return value ? this.$moment(value).format('DD/MM/YYYY') : 'N/A'
    },

    /**
    * Actualización de pago
    *
    * @summary Muéstra el modal de actualización de pago
    * @param {Object} payment - Objeto de pago
    */
    onOpenUpdatePaymentModal(payment) {
      this.setSelectedRefundPayment(payment)
      this.$refs.refundPaymentModal.showModalAndLoadReportPayment(payment)
    },

    /**
     * Actualización de pago
     *
     * @summary Actualiza el pago en la tabla
     * @param {Object} payment - Objeto de pago
     */
    onUpdatePayment(payment) {
      const selectedPayment = this.payments.find(p => p.IdPaymentKey === payment.IdPaymentKey)
      if (selectedPayment) {
        this.$set(selectedPayment, 'TotalMxn', payment.TotalMxn)
        this.$set(selectedPayment, 'saldo_favor', payment.saldo_favor)
        this.$set(selectedPayment, 'Invoice', payment.Invoice)
        this.$set(selectedPayment, 'Payment', payment.Payment)
        this.$set(selectedPayment, 'Currency', payment.Currency)
        this.$set(selectedPayment, 'ExchangeRate', payment.ExchangeRate)
        this.$set(selectedPayment, 'PaymentWithExchangeRate', payment.PaymentWithExchangeRate)
        this.$set(selectedPayment, 'VoucherDate', payment.VoucherDate)
        this.$set(selectedPayment, 'PaymentMethod', payment.PaymentMethod)
        this.$set(selectedPayment, 'IdRefundMethod', payment.IdRefundMethod)
        this.$set(selectedPayment, 'Image', payment.Image)
        this.$set(selectedPayment, 'StatusPayment', payment.StatusPayment)
      }
    },

    /**
    * Gestionador de cambio de opción de columna
    *
    * @summary Gestiona el cambio de opción de columna. Oculta o muestra la columna
    * @param {Object} column - Objeto de la columna
    */
    handleColumnOptionChange(column) {
      const listColumn = this.columns.find(c => c.field === column.value)
      this.$set(listColumn, 'hidden', !column.active)
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
