<template>
  <div>
    <b-form-row>

      <b-col>
        <div class="d-flex align-items-center">
          <span>Vista por</span>

          <!-- #region::Button for showing tables data -->
          <b-button
            v-b-tooltip.hover.bottom="'Tablas'"
            class="mx-50 px-50"
            size="sm"
            @click="showDashboardCharts = false"
          >
            <feather-icon
              icon="LayoutIcon"
            />
          </b-button>
          <!-- #endregion::Button for showing tables data -->

          <!-- #region::Button for showing charts data -->
          <b-button
            v-b-tooltip.hover.bottom="'Gráficos'"
            class="px-50"
            size="sm"
            @click="showDashboardCharts = true"
          >
            <feather-icon
              icon="BarChart2Icon"
            />
          </b-button>
          <!-- #endregion::Button for showing charts data -->

        </div>
      </b-col>

      <!-- #region begin::New cicuit button -->
      <b-col
        cols="12"
        md="auto"
        class="ml-auto"
      >
        <flat-pickr
          v-model="selectedDashboardDateRange"
          class="form-control"
          style="background-color: white;"
          :config="config"
          placeholder="Rango de fechas"
          name="register"
          @on-close="onCloseDashboardDateRange"
        />

        <b-button
          v-if="showClearSelectedDashboardDateRangeButton"
          variant="primary"
          class="mt-1"
          style="padding: 0.5rem 1rem;"
          @click="onClearSelectedDashboardDateRange('register')"
        >
          Limpiar
        </b-button>
      </b-col>
      <!-- #endregion end::New cicuit button -->

      <!-- #region begin::Download CSV button -->
      <b-col
        cols="12"
        md="auto"
      ><b-button
        variant="flat-success"
        class="w-100 csv-btn"
        :disabled="isDownloadingXSLX"
        @click="onDownloadXSLX"
      >
        <b-spinner
          v-if="isDownloadingXSLX"
          class="mr-50"
          small
        />
        <feather-icon
          v-else
          icon="DownloadIcon"
          class="mr-50"
        />
        <span class="align-middle">Descargar CSV</span>
      </b-button></b-col>
      <!-- #endregion end::Download CSV button -->

    </b-form-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BCol, BFormRow, VBTooltip, BSpinner,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies, import/extensions
import { Spanish } from 'flatpickr/dist/l10n/es.js'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/material_blue.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate'

import getError from '@/helpers/ErrorsHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BButton,
    BFormRow,
    BSpinner,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selectedDashboardDateRange: null,
      showClearSelectedDashboardDateRangeButton: false,
      /**
       * Config params for date range picker
       */
      config: {
        mode: 'range',
        locale: Spanish,
        ignoredFocusElements: [window.document.body],
        plugins: [confirmDatePlugin({
          confirmIcon: "<span style='cursor: pointer;'>Aceptar <i class='fa fa-check my-1'></i></span>",
          confirmText: '',
          showAlways: false,
          theme: 'light', // or "dark"
        })],
      },

      isDownloadingXSLX: false,
    }
  },
  computed: {
    ...mapGetters({
      getShowDashboardCharts: 'payments/getShowDashboardCharts',
      getFilteredDashboardDateRange: 'payments/getFilteredDashboardDateRange',
    }),
    /**
     * Bandera para indicar se se deben mostrar los gráficos
     */
    showDashboardCharts: {
      get() { return this.getShowDashboardCharts },
      set(value) { this.setShowDashboardCharts(value) },
    },
    /**
     * Rango de fechas filtrado
     */
    filteredDashboardDateRange: {
      get() { return this.getFilteredDashboardDateRange },
      set(value) { this.setFilteredDashboardDateRange(value) },
    },
  },
  watch: {
    selectedDashboardDateRange(newValue) {
      console.log('selectedDashboardDateRange', newValue)
    },
  },
  mounted() {
    // Carga el estado de rango de fechas
    if (this.filteredDashboardDateRange) {
      this.selectedDashboardDateRange = `${this.filteredDashboardDateRange.from} a ${this.filteredDashboardDateRange.to}`
      this.showClearSelectedDashboardDateRangeButton = true
    } else {
      this.selectedDashboardDateRange = null
      this.showClearSelectedDashboardDateRangeButton = false
    }
  },
  methods: {
    ...mapActions({
      downloadXSLXFile: 'payments/downloadDashboardXSLXFile',
      setShowDashboardCharts: 'payments/setShowDashboardCharts',
      setFilteredDashboardDateRange: 'payments/setFilteredDashboardDateRange',
    }),
    confirmDatePlugin() {
      console.log('confirmDatePlugin')
    },
    /**
    * Evento de selección de fecha
    *
    * @summary Atiendo al evento de selección de fecha del componente flatpickr
    * @param {String} date - Cadena de texto con las fechas seleccionadas
    */
    onCloseDashboardDateRange(_, date) {
      const from = date?.split('a')[0]?.trim()
      const to = date?.split('a')[1]?.trim()

      let dateRange = null

      if (from && to) {
        dateRange = { from, to }
      } else if (from && !to) {
        dateRange = { from, to: from }
      }

      if (dateRange) {
        this.filteredDashboardDateRange = dateRange
        this.showClearSelectedDashboardDateRangeButton = true
      }
    },

    /**
     * Descarga el archivo XSLX
     */
    async onDownloadXSLX() {
      try {
        this.isDownloadingXSLX = true
        await this.downloadXSLXFile()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isDownloadingXSLX = false
      }
    },

    /**
    * Limpieza del campo de rangos de fecha
    *
    * @summary Atiende al evento de limpieza del campo de rangos de fecha
    */
    onClearSelectedDashboardDateRange() {
      this.selectedDashboardDateRange = null
      this.filteredDashboardDateRange = null
      this.showClearSelectedDashboardDateRangeButton = false
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
