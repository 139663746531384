<template>
  <div>
    <b-card
      class="mt-1 border border-dark shadow-none dashboard-card"
      no-body
    >
      <!-- #region::Title -->
      <b-row class="spaced-title">
        <b-col>
          <h4 class="font-weight-bolder">
            Pagos en efectivo USD
          </h4>
        </b-col>
      </b-row>
      <!-- #endregion::Title -->

      <hr class="my-0">

      <!-- #region::Subtitle -->
      <b-row class="spaced-title">
        <b-col class="d-flex">
          <h5 class="font-weight-bolder">
            {{ dashboardUSDCashPaymentsTotal | currency }}
          </h5>
          <h4 class="ml-50 pill-alert alert-gray">
            {{ dashboardDateRange.from | moment("MMMM Do YYYY") }} - {{ dashboardDateRange.to | moment("MMMM Do YYYY") }}
          </h4>
        </b-col>
      </b-row>
      <!-- #endregion::Subtitle -->

      <!-- #region::Registered payments list -->
      <b-card-text>
        <vue-good-table
          id="reports-table"
          class="vgt-table-checkbox-styled"
          style-class="vgt-table condensed align-text-bottom"
          :columns="columns"
          :rows="dashboardUSDCashPaymentsList"
          :sort-options="{
            enabled: false,
          }"
          compact-mode
          @on-cell-click="onCellClick"
        >

          <!-- #region::Empty response -->
          <div slot="emptystate">
            <b-alert
              variant="warning"
              class="my-0"
              show
            >
              <div class="alert-body">
                <span>No se encontraron pagos.</span>
              </div>
            </b-alert>
          </div>
          <!-- #endregion::Empty response -->

          <!-- #region::Column headers -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              <h6 class="capitalize-col-hd spaced-cell">
                <strong>{{ props.column.label }}</strong>
              </h6>
            </span>
          </template>
          <!-- #endregion::Column headers -->

          <!-- #region::Modifying rows cells -->
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- #region::Status cell -->
            <div
              v-if="props.column.field === 'status'"
              class="spaced-cell-xl"
            >
              <h6 class="text-nowrap text-primary">
                {{ props.formattedRow[props.column.field] }}
              </h6>
            </div>
            <!-- #endregion::Status cell -->

            <!-- #region::Custom general cell -->
            <div
              v-else
              class="spaced-cell-xl"
            >
              <h6 class="text-nowrap">
                {{ props.formattedRow[props.column.field] }}
              </h6>
            </div>
            <!-- #endregion::Custom general cell -->

          </template>
          <!-- #endregion::Modifying rows cells -->

        </vue-good-table>
      </b-card-text>
      <!-- #endregion::Registered payments list -->

    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BCardText, BAlert, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import validateCurrency from '@/helpers/CurrencyFormat'

const STATUS_COLUMN_HEADER = 'Estatus'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BCardText,
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: 'Estatus',
          field: 'status',
          tdClass: 'pointer-cursor-cell',
        },
        {
          label: 'Total de pagos',
          field: 'total',
          formatFn: this.isValidCurrency,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getDashboardDateRange: 'payments/getDashboardDateRange',
      getDashboardUSDCashPaymentsList: 'payments/getDashboardUSDCashPaymentsList',
      getDashboardUSDCashPaymentsTotal: 'payments/getDashboardUSDCashPaymentsTotal',
    }),
    /**
     * Rango de fecha seleccionado
     */
    dashboardDateRange: {
      get() { return this.getDashboardDateRange },
    },
    /**
     * Lista de pagos en efectivo USD
     */
    dashboardUSDCashPaymentsList: {
      get() { return this.getDashboardUSDCashPaymentsList },
    },
    /**
     * Total de pagos en efectivo USD
     */
    dashboardUSDCashPaymentsTotal: {
      get() { return this.getDashboardUSDCashPaymentsTotal },
    },
  },
  methods: {
    /**
    * Validacion de datos numéricos
    *
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value) {
      return validateCurrency(value)
    },
    /**
     * Evento que se ejecuta al dar click en una celda de la tabla
     *
     * @summary Emite un evento con el tipo de filtro y el valor a filtrar
     * @param {Object} params - Objeto con los parámetros del evento
     */
    onCellClick(params) {
      const { label } = params.column
      if (label === STATUS_COLUMN_HEADER) {
        const { status } = params.row
        this.$emit('on:filter-by-cash-status', {
          status,
          tab: 'cash',
          type: 'Dólares',
          dashboardDateRange: this.dashboardDateRange,
        })
      }
    },
  },
}
</script>
