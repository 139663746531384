<template>
  <div>
    <DashboardListsFilters />

    <b-row>

      <!-- #region::Registered payments -->
      <b-col md="6">
        <BasicSkeleton
          v-if="isLoadingDashboardsLists"
          height="410px"
        />
        <DashboardRegisteredPaymentsList
          v-else
          @on:filter-by-location="$emit('on:filter-by-location', $event)"
        />
      </b-col>
      <!-- #endregion::Registered payments -->

      <b-col md="6">
        <BasicSkeleton
          v-if="isLoadingDashboardsLists"
          height="410px"
        />
        <DashboardRefundsPaymentsList
          v-else
          @on:filter-by-location="$emit('on:filter-by-location', $event)"
        />
      </b-col>
      <b-col md="12">
        <BasicSkeleton
          v-if="isLoadingDashboardsLists"
          height="410px"
        />
        <DashboardPaymentsMethodsList
          v-else
          @on:filter-by-method="$emit('on:filter-by-method', $event)"
        />
      </b-col>
      <b-col md="6">
        <BasicSkeleton
          v-if="isLoadingDashboardsLists"
          height="410px"
        />
        <DashboardMXNCashPaymentsList
          v-else
          @on:filter-by-cash-status="$emit('on:filter-by-cash-status', $event)"
        />
      </b-col>
      <b-col md="6">
        <BasicSkeleton
          v-if="isLoadingDashboardsLists"
          height="410px"
        />
        <DashboardUSDCashPaymentsList
          v-else
          @on:filter-by-cash-status="$emit('on:filter-by-cash-status', $event)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCol, BRow,
} from 'bootstrap-vue'

import DashboardListsFilters from '@/modules/reports/payments/components/dashboard/DashboardListsFilters.vue'
import DashboardPaymentsMethodsList from '@/modules/reports/payments/components/dashboard/DashboardPaymentsMethodsList.vue'
import DashboardMXNCashPaymentsList from '@/modules/reports/payments/components/dashboard/DashboardMXNCashPaymentsList.vue'
import DashboardUSDCashPaymentsList from '@/modules/reports/payments/components/dashboard/DashboardUSDCashPaymentsList.vue'
import DashboardRefundsPaymentsList from '@/modules/reports/payments/components/dashboard/DashboardRefundsPaymentsList.vue'
import DashboardRegisteredPaymentsList from '@/modules/reports/payments/components/dashboard/DashboardRegisteredPaymentsList.vue'

import getError from '@/helpers/ErrorsHandler'
import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BRow,
    BasicSkeleton,
    DashboardListsFilters,
    DashboardPaymentsMethodsList,
    DashboardRefundsPaymentsList,
    DashboardMXNCashPaymentsList,
    DashboardUSDCashPaymentsList,
    DashboardRegisteredPaymentsList,
  },
  data() {
    return {
      isLoadingDashboardsLists: false,
    }
  },
  computed: {
    ...mapGetters({
      getDashboardDateRange: 'payments/getDashboardDateRange',
      /**
       * Filtros globales
       */
      getFilteredDashboardDateRange: 'filters/getFilteredDashboardDateRange',
    }),
    dashboardDateRange: {
      get() { return this.getDashboardDateRange },
    },
    filteredDashboardDateRange: {
      get() { return this.getFilteredDashboardDateRange },
      set(value) { this.setDashboardDateRange(value) },
    },
  },
  watch: {
    async dashboardDateRange() {
      await this.loadDashboardLists()
    },
  },
  async created() {
    await this.loadDashboardLists()
  },
  methods: {
    ...mapActions({
      /**
       * Filtros globales
       */
      setDashboardDateRange: 'payments/setDashboardDateRange',
      loadDashboardCashPayments: 'payments/loadDashboardCashPayments',
      loadDashboardMethodsPayments: 'payments/loadDashboardMethodsPayments',
      loadDashboardRegisteredRefunds: 'payments/loadDashboardRegisteredRefunds',
      loadDashboardRegisteredPayments: 'payments/loadDashboardRegisteredPayments',
    }),
    /**
    * Evento de filtrado
    *
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de pagos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleFilter(filter) {
      this.filteredDashboardDateRange = filter.value
      this.loadDashboardLists()
    },

    /**
    * Carga las listas del dashboard
    *
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga los registros de cada una de las tablas del dashboard
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de pagos
    */
    async loadDashboardLists() {
      try {
        this.isLoadingDashboardsLists = true

        // Lista de pagos registrados
        await this.loadDashboardMethodsPayments()

        // Lista de métodos de pago
        await this.loadDashboardRegisteredPayments()

        // Lista de pagos en efectivo (0 = MXN, 1 = USD)
        await this.loadDashboardCashPayments(0)
        await this.loadDashboardCashPayments(1)

        // Lista de reembolsos registrados
        await this.loadDashboardRegisteredRefunds()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isLoadingDashboardsLists = false
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },

}
</script>
