<template>
  <div>

    <PaymentsListFilters
      v-show="!isLoadingPayments"
      :title="title"
      :show-only-cash-items="showOnlyCashItems"
      :download-file-base-link="downloadFileBaseLink"
      @on:column-option-change="handleColumnOptionChange"
      @on:filter="handleFilter"
    />

    <template v-if="isLoadingPayments">
      <BasicSkeleton height="150px" />
      <BasicSkeleton height="550px" />
    </template>

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Products list table -->
          <vue-good-table
            v-if="availablePayments"
            id="reports-table"
            ref="payments-products-table"
            class="vgt-table-checkbox-styled"
            style-class="vgt-table condensed align-text-bottom"
            :columns="columns"
            :rows="payments"
            :sort-options="{
              enabled: false,
            }"
          >

            <!-- #region::Empty response -->
            <div slot="emptystate">
              No hay pagos para mostrar.
            </div>
            <!-- #endregion::Empty response -->

            <!-- #region::Column headers -->
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span>
                <h6 class="capitalize-col-hd">
                  <strong>{{ props.column.label }}</strong>
                </h6>
              </span>
            </template>
            <!-- #endregion::Column headers -->

            <!-- #region::Modifying rows cells -->
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- #region::Location cell -->
              <div
                v-if="props.column.field === 'Location'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ (props.row.Location || props.row.QuoteLocation) || 'Sin especificar' }}
                </h6>
              </div>
              <!-- #endregion::Location cell -->

              <!-- #region::Order id cell -->
              <div
                v-else-if="props.column.field === 'ID'"
                class="mt-2"
              >
                <router-link
                  :to="{
                    name: props.row.ID ? 'order-details' : 'quote-details',
                    params: { id: props.row.ID || props.row.QuoteID }
                  }"
                  target="_blank"
                >
                  <h6 class="text-nowrap text-primary">
                    {{ props.row.ID || props.row.QuoteID }}
                  </h6>
                </router-link>
              </div>
              <!-- #endregion::Order id cell -->

              <!-- #region:Order status cell -->
              <div
                v-else-if="props.column.field === 'OrderStatus'"
                class="mt-1"
              >
                <template v-if="props.row.OrderStatus || props.row.QuoteStatusText">
                  <div
                    :class="[
                      styleBorder(props.row.OrderStatus || props.row.QuoteStatusText),
                      styleColor(props.row.OrderStatus || props.row.QuoteStatusText)
                    ]"
                    role="alert"
                    class="text-nowrap"
                    style="display: inline; font-size: 11px;"
                  >
                    {{ props.row.OrderStatus || props.row.QuoteStatusText }}
                  </div>
                </template>
                <h6
                  v-else
                  class="text-nowrap"
                >Sin especificar</h6>
              </div>
              <!-- #endregion:Order status cell -->

              <!-- #region::Total MXN cell -->
              <div
                v-else-if="props.column.field === 'TotalMxn'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ (props.row.TotalMxn || props.row.QuoteTotalMxn) | currency }}
                </h6>
              </div>
              <!-- #endregion::Total MXN cell -->

              <!-- #region::Percentage paid cell -->
              <div
                v-else-if="props.column.field === 'PercentagePaid'"
                class="mt-2"
              >
                <h6
                  v-if="props.row.PercentagePaid"
                  class="text-nowrap"
                >
                  {{ `${props.row.PercentagePaid}%` }}
                </h6>
                <h6
                  v-else
                  class="text-nowrap"
                >
                  N/A
                </h6>
              </div>
              <!-- #endregion::Percentage paid cell -->

              <!-- #region::Payment status cell -->
              <div
                v-else-if="props.column.field === 'PaymentStatus'"
                class="mt-1"
              >
                <h6
                  class="text-nowrap pill-alert"
                  :class="[backgroundPaymentStatusColor(props.row.PaymentStatus)]"
                >
                  {{ props.row.PaymentStatus }}
                </h6>
              </div>
              <!-- #endregion::Payment status cell -->

              <!-- #region::Positive balance cell -->
              <div
                v-else-if="props.column.field === 'CreditBalance'"
                class="mt-2"
              >
                <h6
                  v-if="props.row.CreditBalance"
                  class="text-nowrap"
                >
                  {{ props.row.CreditBalance | currency }}
                </h6>
                <h6
                  v-else
                  class="text-nowrap"
                >
                  N/A
                </h6>
              </div>
              <!-- #endregion::Positive balance cell -->

              <!-- #region::Creator cell -->
              <div
                v-else-if="props.column.field === 'Creator'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.Creator }}
                </h6>
              </div>
              <!-- #endregion::Creator cell -->

              <!-- #region::Payment id cell -->
              <div
                v-else-if="props.column.field === 'IDPayment'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.IDPayment }}
                </h6>
              </div>
              <!-- #endregion::Payment id cell -->

              <!-- #region::Payment id cell -->
              <div
                v-else-if="props.column.field === 'Invoice'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.Invoice.value }}
                </h6>
              </div>
              <!-- #endregion::Payment id cell -->

              <!-- #region::Payment cell -->
              <div
                v-else-if="props.column.field === 'Payment'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.Payment | currency }}
                </h6>
              </div>
              <!-- #endregion::Payment cell -->

              <!-- #region::Currency cell -->
              <div
                v-else-if="props.column.field === 'Currency'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.Currency }}
                </h6>
              </div>
              <!-- #endregion::Currency cell -->

              <!-- #region::Exchange rate cell -->
              <div
                v-else-if="props.column.field === 'ExchangeRate'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ isValidCurrency(props.row.ExchangeRate) }}
                </h6>
              </div>
              <!-- #endregion::Exchange rate cell -->

              <!-- #region::Payment with exchange rate applied cell -->
              <div
                v-else-if="props.column.field === 'PaymentWithExchangeRate'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.PaymentWithExchangeRate | currency }}
                </h6>
              </div>
              <!-- #endregion::Payment with exchange rate applied cell -->

              <!-- #region::Voucher date cell -->
              <div
                v-else-if="props.column.field === 'VoucherDate'"
                class="mt-2"
              >
                <h6
                  v-if="props.row.VoucherDate"
                  class="text-nowrap"
                >
                  {{ props.row.VoucherDate | moment("DD/MM/YYYY") }}
                </h6>
                <h6
                  v-else
                  class="text-nowrap"
                >
                  N/A
                </h6>
              </div>
              <!-- #endregion::Voucher date cell -->

              <!-- #region::Payment method cell -->
              <div
                v-else-if="props.column.field === 'PaymentMethod'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.PaymentMethod }}
                </h6>
              </div>
              <!-- #endregion::Payment method cell -->

              <!-- #region::Registered date cell -->
              <div
                v-else-if="props.column.field === 'RegisteredAt'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.RegisteredAt | moment("DD/MM/YYYY") }}
                </h6>
              </div>
              <!-- #endregion::Registered date cell -->

              <!-- #region::Payment confirmation cell -->
              <div
                v-else-if="props.column.field === 'PaymentConfirmation'"
                class="mt-1"
              >
                <div class="d-flex justify-content-left">
                  <div class="align-self-center"><h6
                    class="text-nowrap pill-alert mr-25"
                    :class="[backgroundPaymentStatusColor(props.row.PaymentConfirmation)]"
                  >
                    {{ props.row.PaymentConfirmation }}
                  </h6></div>

                  <b-button
                    v-if="canShowConfirmPaymentButton(props.row.PaymentConfirmation)"
                    v-b-tooltip.hover.top="'Confirmar'"
                    variant="flat-success"
                    class="btn-icon p-25"
                    @click="onConfirmPayment(props.row)"
                  >
                    <feather-icon icon="CheckIcon" />
                  </b-button>

                  <b-button
                    v-if="canShowCancelPaymentButton(props.row.PaymentConfirmation)"
                    v-b-tooltip.hover.top="'Cancelar'"
                    variant="flat-danger"
                    class="btn-icon p-25"
                    @click="onShowRejectedCommentModal(props.row)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
              <!-- #endregion::Payment confirmation cell -->

              <!-- #region::Payment options cell -->
              <div
                v-else-if="props.column.field === 'paymentOptions'"
                class="mt-1"
              >
                <div class="d-flex justify-content-left">

                  <!-- #region::Preview image button -->
                  <b-button
                    v-if="$ability.can('read acquittance', 'Payments')"
                    v-b-tooltip.hover.top="'Ver recibo'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    @click="$refs.imagePreviewModal.show(props.row.Image)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <!-- #endregion::Preview image button -->

                  <!-- #region::Download receipt button -->
                  <b-button
                    v-if="$ability.can('download acquittance', 'Payments')"
                    v-b-tooltip.hover.top="'Descargar recibo'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    @click="downloadImage(props.row.IdPaymentKey)"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>
                  <!-- #endregion::Download receipt button -->

                  <!-- #region::Edit payment button -->
                  <b-button
                    v-if="canShowEditPaymentButton(props.row.PaymentConfirmation)"
                    v-b-tooltip.hover.top="'Editar'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    @click="onUpdatePayment(props.row)"
                  >
                    <feather-icon icon="Edit3Icon" />
                  </b-button>
                  <!-- #endregion::Edit payment button -->

                </div>
              </div>
              <!-- #endregion::Payment options cell -->

              <!-- #region:Invoice required cell -->
              <div
                v-else-if="props.column.field === 'InvoiceRequired'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.InvoiceRequired }}
                </h6>
              </div>
              <!-- #endregion:Invoice required cell -->

              <!-- #region:Delivery cash cell -->
              <div
                v-else-if="props.column.field === 'DeliveryCash'"
                class="mt-1"
              >
                <div class="d-flex justify-content-left">
                  <div class="align-self-center"><h6
                    class="text-nowrap pill-alert mr-25"
                    :class="[backgroundCashDeliveryStatusColor(props.row.DeliveryCash)]"
                  >
                    {{ props.row.DeliveryCash }}
                  </h6></div>

                  <b-button
                    v-if="canShowConfirmVoucherButton(props.row.DeliveryCash)"
                    v-b-tooltip.hover.top="'Confirmar'"
                    variant="flat-success"
                    class="btn-icon p-25"
                    @click="onUpdateVoucher(props.row, 'confirm')"
                  >
                    <feather-icon icon="CheckIcon" />
                  </b-button>

                  <b-button
                    v-if="canShowCancelVoucherButton(props.row.DeliveryCash)"
                    v-b-tooltip.hover.top="'Cancelar'"
                    variant="flat-danger"
                    class="btn-icon p-25"
                    @click="onUpdateVoucher(props.row, 'cancel')"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>

              </div>
              <!-- #endregion:Delivery cash cell -->

              <!-- #region::Voucher options cell -->
              <div
                v-else-if="props.column.field === 'voucherOptions'"
                class="mt-1"
              >
                <div class="d-flex justify-content-start">

                  <b-button
                    v-if="canShowUploadVoucherButton(props.row.DeliveryCash)"
                    v-b-tooltip.hover.top="'Subir comprobante'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    @click="$refs.paymentImageModal.showModal(props.row)"
                  >
                    <feather-icon icon="UploadIcon" />
                  </b-button>

                  <!-- #region::Preview image button -->
                  <b-button
                    v-if="canShowPreviewVoucherButton(props.row.DeliveryCash)"
                    v-b-tooltip.hover.top="'Ver comprobante'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    @click="$refs.imagePreviewModalVoucher.show(props.row.ImageCashPayment)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <!-- #endregion::Preview image button -->

                  <!-- #region::Download receipt button -->
                  <b-button
                    v-if="canShowDownloadVoucherButton(props.row.DeliveryCash)"
                    v-b-tooltip.hover.top="'Descargar comprobante'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    @click="downloadVoucherImage(props.row.IdPaymentKey)"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>
                  <!-- #endregion::Download receipt button -->

                  <!-- #region::Download receipt button -->
                  <b-button
                    v-if="canShowEditVoucherButton(props.row.DeliveryCash)"
                    v-b-tooltip.hover.top="'Actualizar comprobante'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    @click="$refs.paymentImageModal.showModalAndLoadPayment(props.row)"
                  >
                    <feather-icon icon="RefreshCcwIcon" />
                  </b-button>
                  <!-- #endregion::Download receipt button -->

                </div>
              </div>
              <!-- #endregion::Voucher options cell -->

              <!-- #region::Cash delivery date cell -->
              <div
                v-else-if="props.column.field === 'DeliveryDate'"
                class="mt-2"
              >
                <h6 class="text-nowrap">
                  {{ props.row.DeliveryDate | moment("DD/MM/YYYY") }}
                </h6>
              </div>
              <!-- #endregion::Cash delivery date cell -->

            </template>
            <!-- #endregion::Modifying rows cells -->

          </vue-good-table>
          <!-- #endregion::Products list table -->

          <!-- #region::Alert when no transfers are available -->
          <b-alert
            v-if="!availablePayments && !isLoadingPayments"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron pagos.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no transfers are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availablePayments"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalPayments"
    />
    <!-- #endregion end::Pagination & items per list -->

    <!-- #region::Order payment modal (for editing payment) -->
    <OrderPaymentModal
      v-if="$ability.can('edit payment_order', 'Payments')"
      ref="orderPaymentModal"
      @reload-payments="updatePayments"
    />
    <!-- #endregion::Order payment modal (for editing payment) -->

    <!-- #region::Preview images modals (for payment and voucher) -->
    <ImagePreviewModal ref="imagePreviewModal" />
    <ImagePreviewModal
      ref="imagePreviewModalVoucher"
      :title="'Comprobante de pago'"
    />
    <!-- #endregion::Preview images modals (for payment and voucher) -->

    <!-- #region::Uploading and editing voucher image -->
    <PaymentImageModal
      v-if="$ability.can('upload cash_voucher', 'Payments')"
      ref="paymentImageModal"
    />
    <!-- #endregion::Uploading and editing voucher image -->

    <!-- #region::Comment modal for cancelling payment -->
    <CommentModal
      ref="commentModal"
      :action="validateCancellingComment"
      :title="'Cancelar pago'"
      :sub-title="'Comenta el motivo de cancelación'"
      :succes-title="'Pago cancelado'"
      :success-message="'El pago seleccionado ha sido cancelado correctamente.'"
    />
    <!-- #endregion::Comment modal for cancelling payment -->
  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BCardText, BAlert, VBTooltip, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import ImagePreviewModal from '@/components/modals/ImagePreviewModal.vue'
import OrderPaymentModal from '@/modules/trade/orders/components/modals/OrderPaymentModal.vue'
import PaymentsListFilters from '@/modules/reports/payments/components/PaymentsListFilters.vue'
import PaymentImageModal from '@/modules/reports/payments/components/modals/PaymentImageModal.vue'
import CommentModal from '@/components/modals/CommentModal.vue'

import getError from '@/helpers/ErrorsHandler'
import validateCurrency from '@/helpers/CurrencyFormat'
import { adminLevel, ADMIN_USER_LEVELS } from '@/helpers/UserLevel'
import { statusBorderStyle, statusColorStyle } from '@/helpers/BannersStyles'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

const ROLES_HIDDEN_COLUMNS = ['Vendedor', 'Mayorista']

export default {
  components: {
    BCard,
    BAlert,
    BButton,
    BCardText,
    VueGoodTable,
    BasicSkeleton,
    BasicPaginator,
    ImagePreviewModal,
    OrderPaymentModal,
    PaymentImageModal,
    PaymentsListFilters,
    CommentModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    /**
     * Titulo que se muestra en la pestaña
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Indica si se muestran solo pagos en efectivo
     */
    showOnlyCashItems: {
      type: Boolean,
      default: false,
    },
    /**
     * Enlace base para descargar el archivo CSV
     */
    downloadFileBaseLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lastPage: 0,
      totalPayments: 0,
      columns: [
        {
          label: 'Ubicación',
          field: 'Location',
        },
        {
          label: 'ID',
          field: 'ID',
        },
        {
          label: 'Estatus',
          field: 'OrderStatus',
        },
        {
          label: 'Total MXN',
          field: 'TotalMxn',
        },
        {
          label: 'Porcentaje pagado',
          field: 'PercentagePaid',
        },
        {
          label: 'Estatus de pago',
          field: 'PaymentStatus',
        },
        {
          label: 'Saldo a favor',
          field: 'CreditBalance',
        },
        {
          label: 'Creador',
          field: 'Creator',
          hidden: ROLES_HIDDEN_COLUMNS.includes(JSON.parse(localStorage.userData).Role),
        },
        {
          label: 'ID pago',
          field: 'IDPayment',
        },
        {
          label: 'Folio de pago',
          field: 'Invoice',
          tdClass: this.invoiceCellStyle,
        },
        {
          label: 'Cantidad',
          field: 'Payment',
        },
        {
          label: 'Moneda',
          field: 'Currency',
        },
        {
          label: 'T.C.',
          field: 'ExchangeRate',
        },
        {
          label: 'Total MXN',
          field: 'PaymentWithExchangeRate',
        },
        {
          label: 'Fecha de comprobante',
          field: 'VoucherDate',
        },
        {
          label: 'Forma de pago',
          field: 'PaymentMethod',
        },
        {
          label: 'Registrado',
          field: 'RegisteredAt',
        },
        {
          label: 'Confirmar pago',
          field: 'PaymentConfirmation',
        },
        {
          label: 'Recibo',
          field: 'paymentOptions',
        },
        {
          label: 'Factura requerida',
          field: 'InvoiceRequired',
        },
        {
          label: 'Entrega efectivo',
          field: 'DeliveryCash',
          hidden: !this.showOnlyCashItems,
        },
        {
          label: 'Comprobante',
          field: 'voucherOptions',
          hidden: !this.showOnlyCashItems,
        },
        {
          label: 'Fecha de entregado',
          field: 'DeliveryDate',
          hidden: !this.showOnlyCashItems,
        },
      ],
      payments: [],
      isLoadingPayments: false,
      selectedPayment: null,
      selectedPaymentId: '',

      // Role validation
      adminLevel,
      ADMIN_USER_LEVELS,
    }
  },
  computed: {
    ...mapGetters({
      getListsParams: 'payments/getListsParams',
      getOrdersColumnsList: 'payments/getOrdersColumnsList',
      getCashOrdersColumnsList: 'payments/getCashOrdersColumnsList',
      /**
       * Filtros globales
       */
      getFilteredCreator: 'filters/getFilteredCreator',
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredLocation: 'filters/getFilteredLocation',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
      getFilteredOrderStatus: 'filters/getFilteredOrderStatus',
      getFilteredQuoteStatus: 'filters/getFilteredQuoteStatus',
      getFilteredPaymentStatus: 'filters/getFilteredPaymentStatus',
      getFilteredPaymentMethod: 'filters/getFilteredPaymentMethod',
      getFilteredPaymentCurrency: 'filters/getFilteredPaymentCurrency',
      getFilteredVoucherDateRange: 'filters/getFilteredVoucherDateRange',
      getFilteredRegisterDateRange: 'filters/getFilteredRegisterDateRange',
      getFilteredDeliveryDateRange: 'filters/getFilteredDeliveryDateRange',
      getFilteredOrderPaymentStatus: 'filters/getFilteredOrderPaymentStatus',
      getFilteredCashDeliveryStatus: 'filters/getFilteredCashDeliveryStatus',
    }),
    /**
     * Listas de parametros de filtrado de pestañas
     */
    listsParams: {
      get() { return this.getListsParams },
    },
    /**
     * Lista de columnas
     */
    columnsList: {
      get() { return this.title === 'all' ? this.getOrdersColumnsList : this.getCashOrdersColumnsList },
    },
    /**
     * Indica el creador seleccionado (paginado global)
     */
    filteredCreator: {
      get() { return this.getFilteredCreator },
      set(value) { this.setFilteredCreator(value) },
    },
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Indica la ubicación seleccionada (paginado global)
     */
    filteredLocation: {
      get() { return this.getFilteredLocation },
      set(value) { this.setFilteredLocation(value) },
    },
    /**
     * Indica el término de búsqueda (paginado global)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica el estatus de orden seleccionado (paginado global)
     */
    filteredOrderStatus: {
      get() { return this.getFilteredOrderStatus },
      set(value) { this.setFilteredOrderStatus(value) },
    },
    /**
     * Indica el estatus de cotización seleccionado (paginado global)
     */
    filteredQuoteStatus: {
      get() { return this.getFilteredQuoteStatus },
      set(value) { this.setFilteredQuoteStatus(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    /**
     * Indica el estatus de pago seleccionado (paginado global)
     */
    filteredPaymentStatus: {
      get() { return this.getFilteredPaymentStatus },
      set(value) { this.setFilteredPaymentStatus(value) },
    },
    /**
     * Indica el método de pago seleccionado (paginado global)
     */
    filteredPaymentMethod: {
      get() { return this.getFilteredPaymentMethod },
      set(value) { this.setFilteredPaymentMethod(value) },
    },
    /**
     * Indica la moneda de pago seleccionada (paginado global)
     */
    filteredPaymentCurrency: {
      get() { return this.getFilteredPaymentCurrency },
      set(value) { this.setFilteredPaymentCurrency(value) },
    },
    /**
     * Indica el rango de fechas de comprobante seleccionado (paginado global)
     */
    filteredVoucherDateRange: {
      get() { return this.getFilteredVoucherDateRange },
      set(value) { this.setFilteredVoucherDateRange(value) },
    },
    /**
     * Indica el rango de fechas de registro seleccionado (paginado global)
     */
    filteredRegisterDateRange: {
      get() { return this.getFilteredRegisterDateRange },
      set(value) { this.setFilteredRegisterDateRange(value) },
    },
    /**
     * Indica el rango de fechas de entrega de efectivo seleccionado (paginado global)
     */
    filteredDeliveryDateRange: {
      get() { return this.getFilteredDeliveryDateRange },
      set(value) { this.setFilteredDeliveryDateRange(value) },
    },
    /**
     * Indica el estatus de pago seleccionado (paginado global)
     */
    filteredOrderPaymentStatus: {
      get() { return this.getFilteredOrderPaymentStatus },
      set(value) { this.setFilteredOrderPaymentStatus(value) },
    },
    /**
     * Indica el estatus de la entrega de efectivo (paginado global)
     */
    filteredCashDeliveryStatus: {
      get() { return this.getFilteredCashDeliveryStatus },
      set(value) { this.setFilteredCashDeliveryStatus(value) },
    },
    availablePayments() {
      return this.totalPayments > 0
    },
    isLoadedParamsForList() {
      return this.listsParams.findIndex(params => params.title === this.title) !== -1
    },
    /**
     * Indica si el usuario puede gestionar pagos
     */
    canManagePayments() {
      return this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
  },

  /**
   * Hook que se ejecuta cuando el componente es montado
   *
   * @summary Si la lista ya ha sido cargada previamente, se cargan las columnas
   * que han sido seleccionadas desactivadas
   */
  mounted() {
    if (this.isLoadedParamsForList) {
      const { selectedColumns } = this.getListsParams.find(p => p.title === this.title)
      const hiddenColumns = selectedColumns.filter(c => c.active === false)

      hiddenColumns.forEach(hiddenColumn => {
        this.handleColumnOptionChange(hiddenColumn)
      })
    }
  },

  /**
  * Hook que se ejecuta cuando el componente es creado
  *
  * @summary Carga la lista de traspasos
  */
  async created() {
    await this.loadPaymentsListWithVerification()
  },
  methods: {
    ...mapActions({
      loadPayments: 'payments/loadPayments',
      downloadImage: 'quotes/downloadImage',
      cancelVoucher: 'payments/cancelPayment',
      confirmVoucher: 'payments/confirmPayment',
      cancelOrderPayment: 'orders/cancelPayment',
      cancelQuotePayment: 'quotes/cancelPayment',
      confirmOrderPayment: 'orders/confirmPayment',
      confirmQuotePayment: 'quotes/confirmPayment',
      downloadVoucherImage: 'payments/downloadImage',
      /**
       * Cargar informacion para los filtros
       */
      loadCreatorsSuggests: 'payments/loadCreatorsSuggests',
      /**
       * Filtros globales
       */
      setFilteredCreator: 'filters/setFilteredCreator',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredLocation: 'filters/setFilteredLocation',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
      setFilteredOrderStatus: 'filters/setFilteredOrderStatus',
      setFilteredQuoteStatus: 'filters/setFilteredQuoteStatus',
      setFilteredPaymentStatus: 'filters/setFilteredPaymentStatus',
      setFilteredPaymentMethod: 'filters/setFilteredPaymentMethod',
      setFilteredPaymentCurrency: 'filters/setFilteredPaymentCurrency',
      setFilteredVoucherDateRange: 'filters/setFilteredVoucherDateRange',
      setFilteredRegisterDateRange: 'filters/setFilteredRegisterDateRange',
      setFilteredDeliveryDateRange: 'filters/setFilteredDeliveryDateRange',
      setFilteredOrderPaymentStatus: 'filters/setFilteredOrderPaymentStatus',
      setFilteredCashDeliveryStatus: 'filters/setFilteredCashDeliveryStatus',
    }),

    /**
    * Evento de filtrado
    *
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de pagos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'creator': this.filteredCreator = filter.value
          break
        case 'location': this.filteredLocation = filter.value
          break
        case 'orderStatus': this.filteredOrderStatus = filter.value
          break
        case 'quoteStatus': this.filteredQuoteStatus = filter.value
          break
        case 'voucherDate': this.filteredVoucherDateRange = filter.value
          break
        case 'registerDate': this.filteredRegisterDateRange = filter.value
          break
        case 'deliveryDate': this.filteredDeliveryDateRange = filter.value
          break
        case 'paymentStatus': this.filteredPaymentStatus = filter.value
          break
        case 'paymentMethod': this.filteredPaymentMethod = filter.value
          break
        case 'paymentCurrency': this.filteredPaymentCurrency = filter.value
          break
        case 'orderPaymentStatus': this.filteredOrderPaymentStatus = filter.value
          break
        case 'cashDeliveryStatus': this.filteredCashDeliveryStatus = filter.value
          break
        default: this.filteredSearchTerm = filter.value
          this.filteredCurrentPage = 1
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage()
          break
      }

      this.loadPaymentsListWithVerification()
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    *
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadPaymentsListWithVerification() {
      await this.loadPaymentsList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.filteredCurrentPage = currentPage
      this.filteredPerPage = perPage
      this.loadPaymentsList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.payments.length === 0) {
        this.filteredCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availablePayments) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadPaymentsList()
        }
      }
    },

    /**
    * Carga de registros de tipo pagos
    *
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de pagos y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de pagos
    */
    async loadPaymentsList() {
      try {
        this.isLoadingPayments = true

        const response = await this.loadPayments(this.loadParams())
        this.payments = response.data.data.reports.data
        this.totalPayments = response.data.data.reports.total
        this.lastPage = response.data.data.reports.last_page

        if (this.$ability.can('read creator_order', 'Payments')) {
          await this.loadCreatorsSuggests()
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isLoadingPayments = false
      }
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista. Determina
    * si se muestra solo pagos en efectivo
    */
    loadParams() {
      return {
        title: this.title,
        perPage: this.filteredPerPage,
        currentPage: this.filteredCurrentPage,
        cashTerm: this.showOnlyCashItems ? 1 : 0,
        locationPaymentTerm: this.filteredLocation,
        orderStatusTerm: this.filteredOrderStatus,
        quoteStatusTerm: this.filteredQuoteStatus,
        searchTerm: this.filteredSearchTerm,
        paymentStatusTerm: this.filteredOrderPaymentStatus,
        creatorTerm: this.filteredCreator,
        statusConfirmationTerm: this.filteredPaymentStatus,
        paymentMethodTerm: this.filteredPaymentMethod,
        paymentCurrencyTerm: this.filteredPaymentCurrency,
        deliveryCashTerm: this.filteredCashDeliveryStatus,
        voucherDateStartTerm: this.filteredVoucherDateRange?.from,
        voucherDateEndTerm: this.filteredVoucherDateRange?.to,
        registerDateStartTerm: this.filteredRegisterDateRange?.from,
        registerDateEndTerm: this.filteredRegisterDateRange?.to,
        deliveryDateStartTerm: this.filteredDeliveryDateRange?.from,
        deliveryDateEndTerm: this.filteredDeliveryDateRange?.to,

        selectedColumns: this.isLoadedParamsForList
          ? this.getListsParams.find(p => p.title === this.title).selectedColumns
          : this.columnsList.map(a => ({ ...a })),
      }
    },

    /**
    * Estilo de color de estatus de pago
    *
    * @summary Determina a partir del estatus de pago el color del elemento
    * @param {String} paymentStatus - Estatus de pago
    * @return {String} Clase de color
    */
    backgroundPaymentStatusColor(paymentStatus) {
      switch (paymentStatus) {
        case 'Pago pendiente':
          return 'alert-dark-yellow'
        case 'Pendiente':
          return 'alert-dark-blue'
        case 'Cancelado':
          return 'alert-dark-red'
        default:
          return 'alert-dark-green'
      }
    },

    /**
    * Estilo de color de estatus de pago
    *
    * @summary Determina a partir del estatus de pago el color del elemento
    * @param {String} paymentStatus - Estatus de pago
    * @return {String} Clase de color
    */
    backgroundCashDeliveryStatusColor(paymentStatus) {
      switch (paymentStatus) {
        case 'Por Confirmar':
          return 'alert-dark-blue'
        case 'Entregado':
          return 'alert-dark-green'
        case 'Pendiente':
          return 'alert-dark-gray'
        case 'Cancelado':
          return 'alert-dark-red'
        default:
          return 'alert-dark-gray'
      }
    },

    /**
    * Obtiene el estilo de border
    * @summary Obtiene el estilo de border de acuerdo al estatus de la orden
    * @param {String} status - Estatus de la orden
    * @return {String} Estilo de border
    */
    styleBorder(status) {
      return statusBorderStyle(status)
    },

    /**
    * Obtiene el estilo de color
    * @summary Obtiene el estilo de color de acuerdo al estatus de la orden
    * @param {String} status - Estatus de la orden
    * @return {String} Estilo de color
    */
    styleColor(status) {
      return statusColorStyle(status)
    },

    /**
    * Estilos de celdas
    *
    * @summary Establece los estilos de las celdas de la tabla. Amariilo si el inventario es
    * mayor a 0, rojo si es menor o igual a 0
    * @param {Object} row - Objeto de la fila
    */
    invoiceCellStyle(row) {
      return row?.Invoice.repeat === 'true' ? 'beige-cell' : ''
    },

    /**
    * Validacion de datos numéricos
    *
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value) {
      return validateCurrency(value)
    },

    /**
    * Botón de confirmación de recibo de pago
    *
    * @summary Determina si se muestra el botón de confirmación de recibo de pago
    * @param {String} voucherStatus - Estatus de recibo de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowConfirmVoucherButton(voucherStatus) {
      const statuses = ['Por Confirmar']
      return this.$ability.can('confirm cash_voucher', 'Payments')
        && statuses.includes(voucherStatus)
        && this.canManagePayments
    },

    /**
    * Botón de cancelación de recibo de pago
    *
    * @summary Determina si se muestra el botón de cancelación de recibo de pago
    * @param {String} voucherStatus - Estatus de recibo de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowCancelVoucherButton(voucherStatus) {
      const statuses = ['Por Confirmar']
      return this.$ability.can('cancel cash_voucher', 'Payments')
        && statuses.includes(voucherStatus)
        && this.canManagePayments
    },

    /**
    * Botón de confirmación de pago
    *
    * @summary Determina si se muestra el botón de confirmación de pago
    * @param {String} paymentStatus - Estatus de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowConfirmPaymentButton(paymentStatus) {
      const statuses = ['Pendiente']
      return this.$ability.can('confirm payment_order', 'Payments')
        && statuses.includes(paymentStatus)
        && this.canManagePayments
    },

    /**
    * Botón de cancelación de pago
    *
    * @summary Determina si se muestra el botón de cancelación de pago
    * @param {String} paymentStatus - Estatus de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowCancelPaymentButton(paymentStatus) {
      const statuses = ['Pendiente', 'Pagado']
      return this.$ability.can('cancel payment_order', 'Payments')
        && statuses.includes(paymentStatus)
        && this.canManagePayments
    },

    /**
    * Botón de edición de pago
    *
    * @summary Determina si se muestra el botón de edición de pago
    * @param {String} paymentStatus - Estatus de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowEditPaymentButton(paymentStatus) {
      const statuses = ['Pendiente']
      return this.$ability.can('edit payment_order', 'Payments')
        && statuses.includes(paymentStatus)
    },

    /**
    * Botón de carga de comprobante de pago
    *
    * @summary Determina si se muestra el botón para cargar el comprobante de pago
    * @param {String} paymentStatus - Estatus de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowUploadVoucherButton(deliveryCashStatus) {
      const statuses = ['Pendiente']
      return this.$ability.can('upload cash_voucher', 'Payments')
        && statuses.includes(deliveryCashStatus)
    },

    /**
    * Botón de vista previa
    *
    * @summary Determina si se muestra el botón vista previa del comprobante de pago
    * @param {String} paymentStatus - Estatus de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowPreviewVoucherButton(deliveryCashStatus) {
      const statuses = ['Por Confirmar', 'Entregado', 'Cancelado']
      return this.$ability.can('read voucher', 'Payments')
        && statuses.includes(deliveryCashStatus)
    },

    /**
    * Botón de edición de comprobante de pago
    *
    * @summary Determina si se muestra el botón de edición de comprobante de pago
    * @param {String} paymentStatus - Estatus de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowEditVoucherButton(deliveryCashStatus) {
      const statuses = ['Por Confirmar']
      return this.$ability.can('edit cash_voucher', 'Payments')
        && statuses.includes(deliveryCashStatus)
    },

    /**
    * Botón de descarga de comprobante de pago
    *
    * @summary Determina si se muestra el botón de descarga de comprobante de pago
    * @param {String} paymentStatus - Estatus de pago
    * @return {Boolean} True si se puede mostrar el botón, false en caso contrario
    */
    canShowDownloadVoucherButton(deliveryCashStatus) {
      const statuses = ['Por Confirmar', 'Entregado', 'Cancelado']
      return this.$ability.can('download voucher', 'Payments')
        && statuses.includes(deliveryCashStatus)
    },

    /**
    * Confirmación de pago
    *
    * @summary Atiende el evento para confirmar un pago, si la respuesta es positiva,
    * se actualiza el pago directamente en la tabla y los relacionados con la misma orden
    * @param {Object} payment - Objeto de pago
    */
    async onConfirmPayment(payment) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.loadingPaymentConfirmation = true

        let response = null

        if (payment.ID) {
          response = await this.confirmOrderPayment({
            iddOrder: payment.ID,
            idPayment: payment.IdPaymentKey,
          })
        } else {
          const formData = new FormData()
          formData.append('id', payment.IdPaymentKey)

          response = await this.confirmQuotePayment(formData)
        }

        const isValidResponse = payment.ID ? (response.status === 200) : response.data.success

        if (isValidResponse) {
          if (payment.ID) {
            const paymentResponse = response.data.data
            this.updatePayments(payment, paymentResponse, 'Pagado')
          } else {
            this.$set(payment, 'PaymentConfirmation', 'Pagado')
          }

          this.showToast(
            'Pago confirmado',
            'El pago seleccionado ha sido confirmado correctamente.',
            'success',
          )
        }

        this.loadingPaymentConfirmation = false
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },

    async onUpdateVoucher(payment, action) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        const response = action === 'confirm'
          ? await this.confirmVoucher(payment.IdPaymentKey)
          : await this.cancelVoucher(payment.IdPaymentKey)

        if (response.status === 200) {
          this.$set(payment, 'DeliveryCash', response.data.data.delivery_cash)

          this.showToast(
            'Pago confirmado',
            response.data.message,
            'success',
          )
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },
    /**
     * @summary Muestra el modal para cancelar un pago y setea el pago seleccionado
     * @param {Object} payment - Objeto con la información del pago
     * @returns {void}
     */
    onShowRejectedCommentModal(payment) {
      this.selectedPayment = payment
      this.$refs.commentModal.showModal()
    },
    /**
     * @summary Valida el comentario de cancelación y ejecuta la acción de cancelar el pago
     * @param {string} comment - Comentario de cancelación
     * @returns {void}
     */
    validateCancellingComment(comment) {
      this.onCancelPayment(this.selectedPayment, comment)
    },
    /**
    * Cancelación de pago
    *
    * @summary Atiende el evento para cancelar un pago, si la respuesta es positiva,
    * se actualiza el pago directamente en la tabla
    * @param {Object} payment - Objeto de pago
    */
    async onCancelPayment(payment, comment) {
      let confirmedCancel = true

      if (payment.CreditBalance && payment.PaymentConfirmation === 'Pagado') {
        const result = await this.$swal({
          title: '¿Deseas continuar?',
          text: '¡Al cancelar este pago puede llegar a afectar el saldo a favor de la orden y generar un negativo en su saldo a favor restante!',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        confirmedCancel = result.isConfirmed
      }

      if (confirmedCancel) {
        try {
          this.$swal({
            title: 'Cargando...',
            allowOutsideClick: false,
          })
          this.$swal.showLoading()
          this.loadingPaymentCancelation = true

          let response = null

          if (payment.ID) {
            response = await this.cancelOrderPayment({
              iddOrder: payment.ID,
              idPayment: payment.IdPaymentKey,
              comment,
              cancelFromReportPayment: true,
            })
          } else {
            response = await this.cancelQuotePayment({
              id: payment.IdPaymentKey,
              comment,
            })
          }

          const isValidResponse = payment.ID ? (response.status === 200) : response.data.success

          if (isValidResponse) {
            if (payment.ID) {
              const paymentResponse = response.data.data
              this.updatePayments(payment, paymentResponse, 'Cancelado')
            } else {
              this.$set(payment, 'PaymentConfirmation', 'Cancelado')
            }

            this.showToast(
              'Pago confirmado',
              'El pago seleccionado ha sido cancelado correctamente.',
              'success',
            )

            this.$emit('reload-order')
          }

          this.loadingPaymentCancelation = false
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.$swal.close()
          this.$refs.commentModal.hideModal()
        }
      }
    },

    /**
    * Actualización de pagos
    *
    * @summary Actualiza los pagos relacionados con la misma orden cuando se confirma o cancela un pago
    * @param {Object} selectedPayment - Objeto de pago seleccionado
    * @param {Object} paymentResponse - Objeto de respuesta de pago
    * @param {String} currectStatusConfirmation - Estatus de confirmación actual
    */
    updatePayments(selectedPayment, paymentResponse, currectStatusConfirmation) {
      const sameOrderPayments = this.payments.filter(item => item.ID === selectedPayment.ID)
      sameOrderPayments.forEach(orderPayment => {
        // Si el pago es el mismo que se confirmó, se actualiza el estatus de confirmación
        if (selectedPayment.IdPaymentKey === orderPayment.IdPaymentKey) {
          this.$set(orderPayment, 'PaymentConfirmation', currectStatusConfirmation)
          this.$set(orderPayment, 'Image', selectedPayment.Image)
          this.$set(orderPayment, 'Invoice', selectedPayment.Invoice)
          this.$set(orderPayment, 'Payment', selectedPayment.Payment)
          this.$set(orderPayment, 'Currency', selectedPayment.Currency)
          this.$set(orderPayment, 'VoucherDate', selectedPayment.VoucherDate)
          this.$set(orderPayment, 'ExchangeRate', selectedPayment.ExchangeRate)
          this.$set(orderPayment, 'PaymentMethod', selectedPayment.PaymentMethod)
          this.$set(orderPayment, 'InvoiceRequired', selectedPayment.InvoiceRequired)
          this.$set(orderPayment, 'PaymentWithExchangeRate', selectedPayment.PaymentWithExchangeRate)
          this.$set(orderPayment, 'PositiveBalanceResourceId', selectedPayment.PositiveBalanceResourceId)
          this.$set(orderPayment, 'PositiveBalanceResourceType', selectedPayment.PositiveBalanceResourceType)

          if (this.showOnlyCashItems && currectStatusConfirmation === 'Cancelado') {
            this.$set(orderPayment, 'DeliveryCash', currectStatusConfirmation)
          }
        }

        this.$set(orderPayment, 'PaymentStatus', paymentResponse.paymentStatus)
        this.$set(orderPayment, 'PercentagePaid', paymentResponse.percentagePaid)
        this.$set(orderPayment, 'CreditBalance', paymentResponse.positiveBalance)
      })
    },

    /**
    * Actualización de pago
    *
    * @summary Muéstra el modal de actualización de pago
    * @param {Object} payment - Objeto de pago
    */
    async onUpdatePayment(payment) {
      await this.$refs.orderPaymentModal.showModalAndLoadReportPayment(payment)
    },

    /**
    * Gestionador de cambio de opción de columna
    *
    * @summary Gestiona el cambio de opción de columna. Oculta o muestra la columna
    * @param {Object} column - Objeto de la columna
    */
    handleColumnOptionChange(column) {
      const listColumn = this.columns.find(c => c.field === column.value)
      this.$set(listColumn, 'hidden', !column.active)
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
