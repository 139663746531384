<template>
  <div>
    <b-card
      class="mt-1 border border-dark shadow-none dashboard-card"
      no-body
    >

      <!-- #region::Subtitle -->
      <b-row class="pb-1 pt-2 pl-1">
        <b-col class="d-flex">
          <h4 class="font-weight-bolder">
            Clientes
          </h4>
          <h4 class="ml-50 pill-alert alert-gray align-self-center">
            {{ trasnactionsDateRange.from | moment("MMMM Do YYYY") }} - {{ trasnactionsDateRange.to | moment("MMMM Do YYYY") }}
          </h4>
        </b-col>
      </b-row>
      <!-- #endregion::Subtitle -->

      <!-- #region::Registered payments list -->
      <b-card-text>
        <BasicSkeleton
          v-if="isLoadingPayments"
          height="360px"
        />

        <vue-good-table
          v-else
          id="reports-table"
          class="vgt-table-checkbox-styled"
          style-class="vgt-table condensed align-text-bottom"
          :columns="columns"
          :rows="payments"
          :sort-options="{
            enabled: false,
          }"
          compact-mode
        >

          <!-- #region::Empty response -->
          <div slot="emptystate">
            <b-alert
              variant="warning"
              class="my-0"
              show
            >
              <div class="alert-body">
                <span>No se encontraron pagos.</span>
              </div>
            </b-alert>
          </div>
          <!-- #endregion::Empty response -->

          <!-- #region::Column headers -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              <h6 class="capitalize-col-hd">
                <strong>{{ props.column.label }}</strong>
              </h6>
            </span>
          </template>
          <!-- #endregion::Column headers -->

          <!-- #region::Modifying rows cells -->
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- #region::Custom general cell -->
            <div>
              <h6 class="text-nowrap">
                {{ props.formattedRow[props.column.field] }}
              </h6>
            </div>
            <!-- #endregion::Custom general cell -->

          </template>
          <!-- #endregion::Modifying rows cells -->

        </vue-good-table>

        <!-- #region begin::Pagination & items per list -->
        <div
          v-if="availablePayments"
          class="p-2"
        >
          <BasicPaginator
            ref="basic-paginator"
            :per-page-col="3"
            :total-rows="totalPayments"
            :callback="handleChangePagination"
            :can-apply-global-pagination="false"
          />
        </div>
        <!-- #endregion end::Pagination & items per list -->
      </b-card-text>
      <!-- #endregion::Registered payments list -->

    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCard, BCardText, BAlert, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import getError from '@/helpers/ErrorsHandler'
import validateCurrency from '@/helpers/CurrencyFormat'
import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BCardText,
    VueGoodTable,
    BasicSkeleton,
    BasicPaginator,
  },
  data() {
    return {
      lastPage: 0,
      localPerPage: 10,
      localCurrentPage: 1,
      totalPayments: 0,
      columns: [
        {
          label: 'Nombre',
          field: 'Name',
        },
        {
          label: 'Total de pagos',
          field: 'Payment',
          formatFn: this.isValidCurrency,
        },
      ],
      payments: [],
      isLoadingPayments: false,
    }
  },
  computed: {
    ...mapGetters({
      getTransactionsDateRange: 'payments/getTransactionsDateRange',
    }),
    /**
     * Rango de fecha seleccionado
     */
    trasnactionsDateRange: {
      get() { return this.getTransactionsDateRange },
    },
    availablePayments() {
      return this.totalPayments > 0
    },
  },
  methods: {
    ...mapActions({
      loadTransactionsCustomersPayments: 'payments/loadTransactionsCustomersPayments',
    }),
    /**
    * Carga de elementos de la lista con verificación de paginado
    *
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadPaymentsListWithVerification() {
      await this.loadPaymentsList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.localPerPage = perPage
      this.localCurrentPage = currentPage
      this.loadPaymentsList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.payments.length === 0) {
        this.localCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (!this.payments.length === 0) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadPaymentsList()
        }
      }
    },

    /**
    * Carga de registros de tipo pagos
    *
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de pagos y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de pagos
    */
    async loadPaymentsList() {
      try {
        this.isLoadingPayments = true

        const response = await this.loadTransactionsCustomersPayments(this.loadParams())
        this.payments = response.data.data.data
        this.totalPayments = response.data.data.total
        this.lastPage = response.data.data.last_page
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isLoadingPayments = false
      }
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista. Determina
    * si se muestra solo pagos en efectivo
    */
    loadParams() {
      return {
        perPage: this.localPerPage,
        currentPage: this.localCurrentPage,
      }
    },

    /**
    * Validacion de datos numéricos
    *
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value) {
      return validateCurrency(value)
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
