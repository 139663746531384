<script>
import { HorizontalBar, mixins } from 'vue-chartjs'

export default {
  components: {
    HorizontalBar,
  },
  extends: HorizontalBar,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions)
  },
}
</script>
