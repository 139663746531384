<template>
  <div>
    <b-form-row>

      <!-- #region begin::New cicuit button -->
      <b-col
        cols="12"
        md="auto"
        class="ml-auto"
      >
        <flat-pickr
          v-model="selectedTransactionsDateRange"
          class="form-control"
          style="background-color: white;"
          :config="config"
          placeholder="Rango de fechas"
          name="register"
          @on-close="onCloseTransactionsDateRange"
        />

        <b-button
          v-if="showClearSelectedTransactionsDateRangeButton"
          variant="primary"
          class="mt-1"
          style="padding: 0.5rem 1rem;"
          @click="onClearSelectedDashboardDateRange('register')"
        >
          Limpiar
        </b-button>
      </b-col>
      <!-- #endregion end::New cicuit button -->

      <!-- #region begin::Download XSLX button -->
      <b-col
        v-if="$ability.can('read download_transactions', 'Transactions')"
        cols="12"
        md="auto"
      ><b-button
        variant="flat-success"
        class="w-100 csv-btn"
        :disabled="isDownloadingXSLX"
        @click="onDownloadXSLX"
      >
        <b-spinner
          v-if="isDownloadingXSLX"
          class="mr-50"
          small
        />
        <feather-icon
          v-else
          icon="DownloadIcon"
          class="mr-50"
        />
        <span class="align-middle">Descargar reporte</span>
      </b-button></b-col>
      <!-- #endregion end::Download XSLX button -->

    </b-form-row>
  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BCol, BFormRow, VBTooltip, BSpinner,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies, import/extensions
import { Spanish } from 'flatpickr/dist/l10n/es.js'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/material_blue.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate'

import getError from '@/helpers/ErrorsHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  components: {
    BCol,
    BButton,
    BFormRow,
    BSpinner,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selectedTransactionsDateRange: null,
      showClearSelectedTransactionsDateRangeButton: false,
      /**
       * Config params for date range picker
       */
      config: {
        mode: 'range',
        locale: Spanish,
        ignoredFocusElements: [window.document.body],
        plugins: [confirmDatePlugin({
          confirmIcon: "<span style='cursor: pointer;'>Aceptar <i class='fa fa-check my-1'></i></span>",
          confirmText: '',
          showAlways: false,
          theme: 'light', // or "dark"
        })],

      },
      isDownloadingXSLX: false,
    }
  },
  computed: {
    ...mapGetters({
      getFilteredTransactionsDateRange: 'payments/getFilteredTransactionsDateRange',
    }),
    filteredTransactionsDateRange: {
      get() { return this.getFilteredTransactionsDateRange },
      set(value) { this.setFilteredTransactionsDateRange(value) },
    },
  },

  /**
  * Hook que se ejecuta cuando el componente se ha montado
  *
  * @summary Verifica si hay un rango de fechas seleccionado, si es así,
  * lo carga en el campo de rango de fechas y muestra el botón de limpiar
  */
  mounted() {
    // Carga el estado de rango de fechas
    if (this.filteredTransactionsDateRange) {
      this.selectedTransactionsDateRange = `${this.filteredTransactionsDateRange.from} a ${this.filteredTransactionsDateRange.to}`
      this.showClearSelectedTransactionsDateRangeButton = true
    } else {
      this.selectedTransactionsDateRange = null
      this.showClearSelectedTransactionsDateRangeButton = false
    }
  },
  methods: {
    ...mapActions({
      downloadXSLXFile: 'payments/downloadTransactionsXSLXFile',
      setFilteredTransactionsDateRange: 'payments/setFilteredTransactionsDateRange',
    }),
    /**
    * Evento de selección de fecha
    *
    * @summary Atiendo al evento de selección de fecha del componente flatpickr
    * @param {String} date - Cadena de texto con las fechas seleccionadas
    */
    onCloseTransactionsDateRange(_, date) {
      const from = date?.split('a')[0]?.trim()
      const to = date?.split('a')[1]?.trim()

      let dateRange = null

      if (from && to) {
        dateRange = { from, to }
      } else if (from && !to) {
        dateRange = { from, to: from }
      }

      if (dateRange) {
        this.filteredTransactionsDateRange = dateRange
        this.showClearSelectedTransactionsDateRangeButton = true
      }
    },
    /**
    * Limpieza del campo de rangos de fecha
    *
    * @summary Atiende al evento de limpieza del campo de rangos de fecha
    */
    onClearSelectedDashboardDateRange() {
      this.selectedTransactionsDateRange = null
      this.filteredTransactionsDateRange = null
      this.showClearSelectedTransactionsDateRangeButton = false
    },

    /**
     * Descarga el archivo XSLX
     */
    async onDownloadXSLX() {
      try {
        this.isDownloadingXSLX = true
        await this.downloadXSLXFile()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isDownloadingXSLX = false
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
