<template>
  <div>

    <!-- #region::Payment form -->
    <validation-observer ref="voucherPaymentForm">
      <b-form @submit="$event.preventDefault()">

        <b-modal
          id="payment-image-modal"
          ref="payment-image-modal"
          :title="idImage ? 'Actualizar' : 'Subir pago'"
          centered
          @hidden="onCloseModal"
        >
          <b-card-text v-if="payment">
            <b-row>

              <b-col md="12">
                <h4>Orden #{{ payment.ID }}</h4>
              </b-col>

              <b-col
                md="12"
                class="mb-2"
              >
                <h6>{{ payment.TotalMxn | currency }} total de orden</h6>
              </b-col>

              <!-- #region::Voucher date picker -->
              <b-col md="12">
                <DatePickerWithValidation
                  v-model="voucherDate"
                  vid="voucherDate"
                  rules="required"
                  label="Fecha del comprobante del pago"
                  :show-required-dot="true"
                  name="fecha del comprobante del pago"
                  placeholder="Selecciona la fecha del comprobante del pago"
                />
              </b-col>
              <!-- #endregion::Voucher date picker -->

              <b-col
                md="12"
                class="mt-2"
              >
                <div
                  slot="label"
                  class="d-flex justify-content-start"
                >
                  <small>Imagen del pago</small>
                  <h5 class="text-danger">
                    *
                  </h5>
                </div>
              </b-col>

              <!-- #region::Images inputs group -->
              <b-col md="3">
                <b-form-group>
                  <ImagesLoader
                    ref="img01"
                    :image="images[0]"
                    @image-loaded="imagesLoaded = true"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-alert
                  variant="danger"
                  :show="!imagesLoaded && submitted"
                  class="mb-2"
                >
                  <div class="alert-body">
                    <small>Es necesario agregar imagen del pago</small>
                  </div>
                </b-alert>
              </b-col>

              <b-col md="6">
                <small class="text-primary">Arrastra o selecciona la imagen</small>
              </b-col>
              <!-- #endregion::Images inputs group -->

            </b-row>
          </b-card-text>

          <!-- #region::Footer -->
          <template #modal-footer>
            <b-button
              variant="delete-btn"
              class="delete-btn"
              @click="hideModal"
            >
              Cancelar
            </b-button>
            <b-button
              variant="principal-btn"
              class="principal-btn"
              @click="onSavePayment"
            >
              {{ idImage ? 'Actualizar' : 'Aceptar' }}
            </b-button>
          </template>
          <!-- #endregion::Footer -->

        </b-modal>
      </b-form>
    </validation-observer>
    <!-- #endregion::Payment form -->

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver, localize } from 'vee-validate'
import {
  BForm, BModal, VBModal, BCardText, BRow, BCol, BAlert, BFormGroup, BButton,
} from 'bootstrap-vue'

import ImagesLoader from '@/components/forms/ImagesLoader.vue'
import DatePickerWithValidation from '@/components/forms/DatePickerWithValidation.vue'

import getError from '@/helpers/ErrorsHandler'
import convertImageToBase64 from '@/helpers/ConvertImageToBase64'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BAlert,
    BButton,
    BCardText,
    BFormGroup,
    ImagesLoader,
    ValidationObserver,
    DatePickerWithValidation,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      images: [],
      payment: null,
      voucherDate: '',

      submitted: false,
      imagesLoaded: false,

      /**
       * Id de la imagen que se edita
       */
      idImage: null,
    }
  },
  created() {
    localize('es')
  },
  methods: {
    ...mapActions({
      saveVoucherImage: 'payments/saveVoucherImage',
      downloadImageBlob: 'payments/downloadImageBlob',
    }),
    /**
     * Muestra el modal
     *
     * @summary Muestra el modal y establece la información del pago
     */
    showModal(payment) {
      this.payment = payment
      this.$refs['payment-image-modal'].show()
    },

    /**
     * Muestra el modal
     *
     * @summary Muestra el modal y carga la información del pago
     */
    async showModalAndLoadPayment(payment) {
      this.payment = payment
      this.idImage = payment.IdImageCashPayment
      this.voucherDate = payment.DeliveryDate

      const blob = await this.downloadImageBlob(payment.IdPaymentKey)
      const base64 = await convertImageToBase64(blob)

      this.images = [{ imagesCode: base64, image: payment.ImageCashPayment }]

      this.$refs['payment-image-modal'].show()
    },

    async onSavePayment() {
      const success = await this.$refs.voucherPaymentForm.validate()
      this.submitted = true

      if (!this.$refs.img01.file) {
        this.imagesLoaded = false
        return
      }

      if (success) {
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()

          const formData = new FormData()

          if (this.idImage) {
            formData.append('idImage', this.idImage)
          }

          formData.append('idPayment', this.payment.IdPaymentKey)
          formData.append('voucher_date', this.voucherDate)
          formData.append('files[]', this.$refs.img01.file)

          // eslint-disable-next-line no-restricted-syntax
          for (const pair of formData.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`)
          }

          const response = await this.saveVoucherImage(formData)
          const paymentResponse = response.data.data

          this.$set(this.payment, 'DeliveryCash', paymentResponse.DeliveryCash)
          this.$set(this.payment, 'DeliveryDate', paymentResponse.DeliveryDate)
          this.$set(this.payment, 'ImageCashPayment', paymentResponse.ImageCashPayment)
          this.$set(this.payment, 'IdImageCashPayment', paymentResponse.IdImageCashPayment)

          this.showToast(
            'Actualización de comprobante',
            'El comrpobante se ha almacenado correctamente',
            'success',
          )

          this.hideModal()
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.$swal.close()
        }
      }
    },

    /**
     * Cancelación de pago
     *
     * @summary Limpia los campos del formulario y oculta el modal
     */
    onCloseModal() {
      this.idImage = null
      this.images = []
      this.payment = null
      this.voucherDate = ''
      this.submitted = false
    },

    /**
     * Cierre de modal
     *
     * @summary Oculta el modal
     */
    hideModal() {
      this.$refs['payment-image-modal'].hide()
    },

    /**
    * Mensaje de notificación
    *
    * @summary Muestra un mensaje de notificación
    * @param {String} title - Título del mensaje
    * @param {String} text - Texto del mensaje
    * @param {String} variant - Tipo del mensaje
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
